<template>
  <div class="upload-files">
    <v-card>
      <v-card-title class="upload-files-header headline grey lighten-2">
        <v-file-input
          v-model="newFile.files"
          show-size
          multiple
          truncate-length="200"
          accept=".json, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        ></v-file-input>
        <v-btn @click="uploadNewFile">Lataa tiedosto</v-btn>
      </v-card-title>

      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Nimi</th>
                <th>Brändi</th>
                <th>PVM</th>
                <th>Kommentti</th>
                <th>Status</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="file in uploadedFiles" :key="file.id">
                <td>{{ file.name }}</td>
                <td>{{ file.supplier }}</td>
                <td>{{ file.created }}</td>
                <td>{{ file.comment }}</td>
                <td>{{ file.status }}</td>
                <td>
                  <v-btn
                    v-if="file.crotools"
                    @click="readFile(file)"
                    :disabled="loading[file.id] || !['new', 'error'].includes(file.status)"
                    >Lue</v-btn
                  >
                  <v-btn
                    v-if="file.crotools"
                    @click="toggleQueue(file)"
                    :disabled="loading[file.id] || !['new', 'in_queue'].includes(file.status)"
                    >Jonoon</v-btn
                  >
                  <router-link v-else :to="`/upload/${file.id}/parse/`"
                    >Parsi</router-link
                  >
                </td>
                <td>
                  <v-btn icon @click="deleteFile(file)"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "UploadFiles",
  data: () => ({
    uploadedFiles: [],
    choices: [],
    newFile: {},
    loading: {},
  }),

  mounted() {
    this.fetchData();
    this.axios.get("/api/products/choices/").then((resp) => {
      this.choices = resp.data;
    });
  },

  computed: {
    suppliersById() {
      let ret = {};
      if (this.choices?.suppliers) {
        for (const supplier of this.choices.suppliers) {
          ret[supplier.id] = supplier;
        }
      }
      return ret;
    },
  },

  methods: {
    toggleQueue(file) {
      if (file.status == 'parsed' || file.status == 'in_progress') {
        return
      }
      let newStatus = 'in_queue'
      if (file.status == 'in_queue') {
        newStatus = 'new'
      }
      this.$set(this.loading, file.id, true);
      this.axios
        .post(`/api/products/uploadedfile/${file.id}/read/`, {
          status_update: newStatus
        })
        .then((resp) => {
          this.$set(this.loading, file.id, false);
          file.status = resp.data.file.status;
        });
    },
    readFile(file) {
      this.$set(this.loading, file.id, true);
      this.axios
        .post(`/api/products/uploadedfile/${file.id}/read/`)
        .then((resp) => {
          file.status = resp.data.file.status;
          this.$store.commit("addAlert", {
            type: "success",
            text:
              `Uudet tuotteet: ${resp.data.result.new_products}\n` +
              `Päivitetyt tuotteet: ${resp.data.result.existing_products}\n` +
              `Uudet värit: ${resp.data.result.new_colors}\n` +
              `Uudet koot: ${resp.data.result.new_sizes}\n` +
              `Uudet ryhmät: ${resp.data.result.new_groups}\n` +
              `Uudet tyypit: ${resp.data.result.new_types}\n`,
          });
        })
        .finally(() => {
          this.$set(this.loading, file.id, false);
        });
    },
    fetchData() {
      this.axios.get("/api/products/uploadedfiles/").then((resp) => {
        this.uploadedFiles = resp.data.results;
      });
    },
    uploadNewFile() {
      const promises = [];
      for (const fileData of this.newFile.files) {
        let formData = new FormData();
        console.log(fileData);
        formData.append("file", fileData, fileData.name);
        formData.append("name", fileData.name);
        promises.push(
          this.axios
            .post("/api/products/uploadedfile/", formData)
            .then((resp) => {
              console.log(resp);
            })
        );
      }
      Promise.all(promises).then(() => {
        this.fetchData();
      });
    },
    deleteFile(uploadedFile) {
      if (confirm(`Poistetaanko tiedosto ${uploadedFile.name}`)) {
        this.axios
          .delete(`/api/products/uploadedfile/${uploadedFile.id}/`)
          .then((resp) => {
            console.log(resp);
            this.fetchData();
          })
          .catch(this.errorHandler);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.upload-files-header {
  display: flex;
  > * {
    max-width: 30%;
    margin-left: 1rem;
  }
  > *:nth-child(1) {
    margin-left: 0;
  }
}
</style>
