<template>
  <div class="wrapper">
    <v-dialog v-if="this.groupsLength > 1" v-model="modal" width="600" @keydown="modalKeyDownHandler">
      <v-card>
        <v-card-title>
          <div style="width: 100%">
            {{ singleGroup.name }} {{ singleGroup.color__name }}
          </div>
          <div style="width: 100%">
            {{ (stores[singleGroup.store] || {}).name }}
          </div>
          <div style="width: 100%">vko {{ singleGroup.delivery_date }}</div>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <tr>
              <th>Koko</th>
              <th v-for="sizeId in Object.keys(groups)" :key="`${sizeId}`">
                {{ sizes[sizeId].name }}
              </th>
            </tr>
            <tr>
              <td>Määrä</td>
              <td v-for="(row, sizeId) in groups" :key="`${sizeId}`">
                <order-row-edit :row="row">
                  <template v-slot="{ newRow, updateFunc }">
                    <cell-input
                      ref="inputs"
                      :allowTab="true"
                      size="3"
                      type="text"
                      :required="true"
                      v-model="newRow.quantity"
                      @keydown="updateFunc()"
                    />
                  </template>
                </order-row-edit>
              </td>
            </tr>
          </v-simple-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" text @click="modal = false"> Sulje </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <order-row-edit v-if="this.groupsLength == 1" :row="singleGroup">
      <template v-slot="{ newRow, updateFunc }">
        <cell-input
          size="3"
          type="text"
          :required="true"
          :disabled="!editable"
          :text-align="'center'"
          v-model="newRow.quantity"
          @keydown="updateFunc()"
        />
      </template>
    </order-row-edit>
    <cell-input
      v-else
      :disabled="true"
      :value="groupSum"
      :text-align="'center'"
      @click.native="showModal"
    >
    </cell-input>
  </div>
</template>

<script>
// Given a `groups` object, show a cellinput which is disabled, if multiple groups
// exist. Then on click a modal opends with all groups editable

import OrderRowEdit from "../components/OrderRowEdit.vue";
import CellInput from "../components/CellInput.vue";
export default {
  name: "PivotOrderInputModal",

  components: { CellInput, OrderRowEdit },
  props: {
    thirdGroup: {
      type: Object,
      default: () => ({}),
    },
    sizes: {
      type: Object,
      default: () => ({}),
    },
    stores: {
      type: Object,
      default: () => ({}),
    },
    editable: {
      type: Boolean,
      default: () => false,
    },
    rows: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    modal: false,
  }),

  computed: {
    groupsLength() {
      if (!this.thirdGroup.groups) {
        return 0;
      }
      return Object.values(this.thirdGroup.groups).length;
    },
    singleGroup() {
      if (this.groupsLength == 0) {
        return {};
      }
      return this.rows[Object.values(this.thirdGroup.groups)[0]];
    },
    groupSum() {
      if (!this.thirdGroup.groups) {
        return 0;
      }
      return Object.values(this.thirdGroup.groups).reduce(
        (acc, row) => parseInt(this.rows[row].quantity || 0) + acc,
        0
      );
    },
    groups() {
      const ret = {};
      for (const [sizeId, rowUid] of Object.entries(this.thirdGroup.groups)) {
        ret[sizeId] = this.rows[rowUid];
      }
      return ret;
    },
  },

  methods: {
    showModal() {
      if (!this.editable) {
        return;
      }
      this.modal = true;
    },
    modalKeyDownHandler(event) {
      if (event.code == "Enter") {
        this.modal = false;
      }
    },
  },

  watch: {
    modal(newValue) {
      if (newValue) {
        setTimeout(() => {
          console.log(this.$refs);
          const el = this.$refs.inputs[0].$el.querySelector("input");
          el.focus();
        }, 100);
      }
    },
  },
};
</script>

<style scoped lang="scss">
td {
  border: 1px solid black;
  padding: 0.5rem;
}
</style>
