<template>
  <v-container>
    <v-row>
      <v-col>
        <h4>Tilaus #{{ $route.params.orderId }}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn :disabled="orderIsSent" @click="markAsSent"
          >Merkkaa lähetetyksi</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox
          v-model="storeVersion"
          label="Vain suositushinnat (myymäläversio)"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="exportOrder">Excel</v-btn>
      </v-col>
      <v-col>
        <v-btn @click="exportStoreOrder">Myymäläkohtainen Excel</v-btn>
      </v-col>
      <v-col>
        <v-btn @click="exportEANStoreOrder"
          >Myymäläkohtainen Excel (Enarit)</v-btn
        >
      </v-col>
    </v-row>
    <v-row v-if="order">
      <v-col>
        <a
          :href="`/media/dynamic/orders/${order.supplier_slug}_${
            $route.params.orderId
          }.xlsx?cacheparam=${Date.now()}`"
          >Viimeksi generoitu excel
          <span v-if="exportTime">({{ exportTime }})</span></a
        >
      </v-col>
    </v-row>
    <v-row v-if="!order || loading">
      <v-col>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ExportOrder",
  components: {},
  data: () => ({
    order: null,
    storeVersion: false,
    loading: false,
    lastExportTime: null,
  }),
  mounted() {
    this.fetchOrder();
  },
  methods: {
    fetchOrder() {
      return this.axios
        .get("/api/orders/order/" + this.$route.params.orderId + "/edit/")
        .then((resp) => {
          this.order = resp.data;
        });
    },
    fetchOrderUntilExportTimeChanges() {
      this.fetchOrder().then(() => {
        if (this.exportTime != this.lastExportTime) {
          this.loading = false;
          return;
        }
        setTimeout(() => {
          this.fetchOrderUntilExportTimeChanges();
        }, 1000);
      });
    },
    startWaiting() {
      this.lastExportTime = this.exportTime;
      this.loading = true;
      this.fetchOrderUntilExportTimeChanges();
    },
    saveTemplateAsFile(filename, dataObjToWrite) {
      const blob = new Blob([JSON.stringify(dataObjToWrite)], {
        type: "text/json",
      });
      const link = document.createElement("a");

      link.download = filename;
      link.href = window.URL.createObjectURL(blob);
      link.dataset.downloadurl = ["text/json", link.download, link.href].join(
        ":"
      );

      const evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });

      link.dispatchEvent(evt);
      link.remove();
    },
    exportOrder() {
      this.axios
        .post("/api/orders/order/" + this.$route.params.orderId + "/export/", {
          store_version: this.storeVersion,
        })
        .then((resp) => {
          console.log(resp);
          this.startWaiting();
        });
    },
    exportStoreOrder() {
      this.axios
        .post("/api/orders/order/" + this.$route.params.orderId + "/export/", {
          export_type: "per_store",
          store_version: this.storeVersion,
        })
        .then((resp) => {
          console.log(resp);
          this.startWaiting();
        });
    },
    exportEANStoreOrder() {
      this.axios
        .post("/api/orders/order/" + this.$route.params.orderId + "/export/", {
          export_type: "ean_per_store",
          store_version: this.storeVersion,
        })
        .then((resp) => {
          console.log(resp);
          this.startWaiting();
        });
    },
    markAsSent() {
      if (
        !confirm(
          "Oletko varma? Tätä ei voi perua, mutta tilausta voi vielä korjata korjaustilassa."
        )
      ) {
        return;
      }
      this.axios
        .post(`/api/orders/order/${this.$route.params.orderId}/edit/`, {
          status: "sent",
        })
        .then((resp) => {
          this.fetchOrder();
        });
    },
  },
  computed: {
    exportTime() {
      if (!this.order) {
        return null;
      }
      return new Date(this.order.excel_export_time).toLocaleString();
    },
    orderIsSent() {
      if (!this.order) {
        return true;
      }
      if (
        ["sent", "exported", "confirmed", "cancelled", "fixing"].includes(
          this.order.status
        )
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
