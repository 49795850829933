<template>
  <div class="parse-file">
    <v-card>
      <v-card-title class="parse-file-header headline grey lighten-2">
        <v-btn
          @click="startParse()"
          v-if="Object.values(fieldMappings).length == fieldsToMap.length"
          >Aloita parse</v-btn
        >
        <span v-if="loading">Ladataan....</span>
        <div class="draggable-fields" v-else-if="headerIndex">
          <div
            draggable="true"
            @dragstart="startDragField(field)"
            @dragend="stopDragField(field)"
            class="draggable-field"
            :class="{ used: field.name in fieldMappings }"
            v-for="field in fieldsToMap"
            :key="field.name"
            @mouseenter="highlightedColumn = fieldMappings[field.name]"
            @mouseleave="highlightedColumn = null"
          >
            {{ field.verbose_name }}
          </div>
        </div>
      </v-card-title>

      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr
                v-for="(row, index) in sheet"
                :key="`row-${index}`"
                :class="{
                  'header-row': index === headerIndex,
                  hidden: index < headerIndex,
                }"
              >
                <td>
                  <v-btn
                    v-if="headerIndex === null"
                    @click="headerIndex = index"
                    >Otsikko</v-btn
                  >
                </td>
                <td
                  v-for="(value, valueIndex) in row"
                  :key="`value-${valueIndex}`"
                  :class="{
                    'drag-target': !!draggingField && index === headerIndex,
                    'drag-hover': dragHoverIndex === valueIndex,
                    highlighted:
                      index === headerIndex && highlightedColumn === valueIndex,
                    mapped:
                      index === headerIndex &&
                      Object.values(fieldMappings).includes(valueIndex),
                  }"
                  @drop="onDropField(index, valueIndex)"
                  @dragover.prevent
                  @dragenter.prevent="dragHoverIndex = valueIndex"
                  @dragleave.prevent="
                    if (dragHoverIndex === valueIndex) {
                      dragHoverIndex = null;
                    }
                  "
                >
                  {{ value }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ParseFile",
  data: () => ({
    loading: false,
    choices: {},
    sheets: [],
    headerIndex: null,
    draggingField: null,
    dragHoverIndex: null, // Argh...

    highlightedColumn: null,
    fieldMappings: {},
  }),

  mounted() {
    this.fetchData();
    this.axios.get("/api/products/choices/").then((resp) => {
      this.choices = resp.data;
    });
  },

  computed: {
    sheet() {
      // FIXME: Multiple sheets
      if (this.sheets.length == 0) {
        return [];
      }
      return this.sheets[0];
    },
    fieldsToMap() {
      if (!this.choices?.productfields) {
        return [];
      }
      return this.choices.productfields.filter((field) => {
        if (field.name == "raw_data") {
          return false;
        }
        return true;
      });
    },
  },

  methods: {
    fetchData() {
      this.loading = true;
      this.axios
        .get(`/api/products/uploadedfile/${this.$route.params.fileId}/`)
        .then((resp) => {
          this.sheets = resp.data.uploaded_data;
          this.loading = false;
        });
    },
    startParse() {
      this.axios
        .post(
          `/api/products/uploadedfile/${this.$route.params.fileId}/parse/`,
          {
            header_index: this.headerIndex,
            field_mappings: this.fieldMappings,
          }
        )
        .then((resp) => {
          console.log(resp.data);
          this.$store.commit("addAlert", {
            type: "success",
            text: resp.data,
          });
        })
        .catch(this.errorHandler);
    },
    startDragField(field) {
      this.draggingField = field;
    },
    stopDragField(field) {
      this.draggingField = null;
    },
    onDropField(rowIndex, valueIndex) {
      console.log(rowIndex, valueIndex);
      if (rowIndex !== this.headerIndex) {
        return;
      }
      this.$set(this.fieldMappings, this.draggingField.name, valueIndex);
    },
  },
};
</script>

<style scoped lang="scss">
tr.header-row {
  background: #eeeeee;
}
tr.hidden {
  display: none;
}
td.drag-target {
  background-color: rgb(0, 207, 0);
  &.drag-hover {
    background-color: rgb(160, 243, 160) !important;
  }
}
td.mapped {
  background-color: rgb(160, 243, 160) !important;
}
td.highlighted {
  background-color: rgb(91, 107, 255) !important;
}
.draggable-fields {
  display: flex;
  flex-wrap: wrap;
  .draggable-field {
    border: 1px solid black;
    margin: 0.5rem;
    padding: 0.5rem;
    &.used {
      background-color: rgb(140, 236, 140);
    }
  }
}
</style>
