<template>
  <div>
    <span
      class="draggable-abs"
      :style="{ left: left + 'px', position: 'absolute' }"
      ref="leftEl"
    >
      <v-icon>mdi-drag</v-icon></span
    ><span
      class="draggable-abs"
      :style="{ left: right + 'px', position: 'absolute' }"
      ref="rightEl"
      ><v-icon>mdi-drag</v-icon></span
    >
  </div>
</template>

<script>
export default {
  name: "HorizontalDragInput",

  props: ["value"],

  data: () => ({
    left: null,
    right: null,

    pos1: null,
    pos2: null,

    dragEl: null,
  }),

  mounted() {
    this.left = this.value.left;
    this.right = this.value.right;
    this.dragElement(this.$refs.leftEl);
    this.dragElement(this.$refs.rightEl);
  },

  watch: {
    modelValue: {
      handler(newValue) {
        this.left = newValue.left;
        this.right = newValue.right;
      },
      deep: true,
    },
  },

  methods: {
    handleCollision(type) {
      if (this.right - this.left < 100) {
        if (type == "right") {
          this.right = this.left + 100;
        } else {
          this.left = this.right - 100;
        }
      }
      if (this.left < 100) {
        this.left = 100;
      }
    },
    // Make the DIV element draggable:
    dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      this.pos2 = e.clientX;
      document.onmouseup = this.closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = this.elementDrag;
    },

    elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      this.pos1 = this.pos2 - e.clientX;
      this.pos2 = e.clientX;
      // set the element's new position:
      if (this.dragEl == this.$refs.leftEl) {
        this.left = this.dragEl.offsetLeft - this.pos1;
        this.handleCollision("left");
      } else {
        this.right = this.dragEl.offsetLeft - this.pos1;
        this.handleCollision("right");
      }
    },

    closeDragElement() {
      // stop moving when mouse button is released:
      document.onmouseup = null;
      document.onmousemove = null;
      this.$emit("input", {
        left: this.$refs.leftEl.offsetLeft,
        right: this.$refs.rightEl.offsetLeft,
      });
      this.dragEl = null;
    },
    dragElement(elmnt) {
      this.pos1 = 0;
      this.pos2 = 0;
      elmnt.onmousedown = () => {
        this.dragEl = elmnt;
        this.dragMouseDown();
      };
    },
  },
};
</script>

<style>
.draggable-abs {
  position: absolute;
  cursor: pointer;
}
</style>
