<script>
// Includues helper functions for finding related selectedproducts etc
export default {
  name: "OrderHelpers",
  mounted() {},
  methods: {
    getSelectedProduct(colorGroup, size) {
      return this.order.selectedproducts[
        this.order.selectedproducts_by_color_group[colorGroup.color_group_str][
          size.id
        ]
      ];
    },
    getSelectedProducts(colorGroup) {
      // Get all selectedProducts for a colorGroup
      const ret = [];
      for (const selectedProductId of Object.values(
        this.order.selectedproducts_by_color_group[colorGroup.color_group_str]
      )) {
        ret.push(this.order.selectedproducts[selectedProductId]);
      }
      return ret;
    },
    colorGroupKeyGetter(colorGroup, key) {
      if (["name", "color__name"].includes(key)) {
        return colorGroup[key];
      }
      if (["net_price", "purchase_price"].includes(key)) {
        const selectedproduct = this.getSelectedProducts(colorGroup)[0];
        if (selectedproduct[key]) {
          return parseFloat(selectedproduct[key]);
        }
        if (key === "net_price") {
          return parseFloat(selectedproduct.net_price);
        }
        if (selectedproduct.product && selectedproduct.product[key]) {
          return parseFloat(selectedproduct.product[key]);
        }
      }
      if (key == "selectedproduct") {
        return (this.getSelectedProducts(colorGroup)[0] || {}).id;
      }
      if (key == "selectedproduct__color_group") {
        return (this.getSelectedProducts(colorGroup)[0] || {}).color_group;
      }
      return colorGroup[key];
    },
  },
  computed: {
    sizeGroups() {
      if (!this.order) {
        return [];
      }
      return Object.keys(this.order.all_sizes);
    },
    sizesByGroup() {
      if (!this.order) {
        return [];
      }
      return this.order.all_sizes;
    },
  },
  watch: {},
};
</script>
