<script>
export default {
  data: () => ({
    stickyTHEAD: null,
    theads: [],
  }),
  mounted() {
    window.addEventListener("scroll", this.handleStickyTHEAD);
    setInterval(() => {
      this.theads = [];
      for (const thead of document.querySelectorAll("table thead")) {
        this.theads.push(thead);
      }
    }, 1000);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleStickyTHEAD);
  },
  methods: {
    getOffset(el) {
      const rect = el.getBoundingClientRect();
      return {
        left: rect.left,
        top: rect.top,
      };
    },
    handleStickyTHEAD() {
      // Figure out which thead element should be sticky (if any)
      let bestTop = null;
      let bestEl = null;
      for (const thead of this.theads) {
        const top = this.getOffset(thead).top;
        if (top < 100 && (bestTop == null || bestTop < top)) {
          bestTop = top;
          bestEl = thead;
        }
      }
      if (bestEl) {
        this.stickyTHEAD = bestEl;
      } else if (this.stickyTHEAD) {
        this.stickyTHEAD = null;
      }
    },
  },
  computed: {},
  watch: {
    stickyTHEAD(newValue) {
      if (newValue) {
        const cloned = newValue.cloneNode(true);
        this.$refs["sticky-header"].innerHTML = "";
        this.$refs["sticky-header"].appendChild(cloned);

        for (let i = 0; i < newValue.children[0].children.length; i++) {
          const width = newValue.children[0].children[i].offsetWidth;
          cloned.children[0].children[i].setAttribute(
            "style",
            `width: ${width}px;`
          );
        }
      } else {
        this.$refs["sticky-header"].innerHTML = "";
      }
    },
  },
};
</script>
