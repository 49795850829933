
import { modifyObj } from "../utils/utils";
import { cloneDeep, update } from "lodash";
import axios from "axios";

const applyChanges = ({ state, commit }) => {
  console.log("applyChanges: ", state.saveValues);
  const rowsToSend = [];
  const oldRowsToSend = [];
  const clonedValues = [];
  const callbacks = [];
  for (const values of Object.values(state.saveValues)) {
    const newRow = values.newRowClone;
    rowsToSend.push(newRow);
    const oldRow = values.oldRowClone;
    oldRowsToSend.push(oldRow);
    const newRowRef = values.newRow;
    const oldRowRef = values.oldRow;
    clonedValues.push({ newRow, oldRow, newRowRef, oldRowRef });
    if (values.changed) {
      callbacks.push(values.changed);
    }
  }
  // Clear the values
  for (const key of Object.keys(state.saveValues)) {
    delete state.saveValues[key];
  }
  commit(
    "addJob",
    {
      callback: () => {
        console.debug("saveQuantity: ", rowsToSend);

        return axios
          .post(`/api/orders/order/${state.pickedOrderId}/update_rows/`, {
            rows: rowsToSend,
          })
          .then((resp) => {
            console.log("saveQuantity resp:", resp);
            // Replace the old row data with new (keep the reference!)
            let index = -1;
            if (resp.data.updated.length > 0) {
              const painted = {};
              for (const updatedRow of resp.data.updated) {
                if (updatedRow.quantity) {
                  painted[updatedRow.store_date_size_color_group_str] = 'not-empty';
                } else {
                  painted[updatedRow.store_date_size_color_group_str] = 'empty';
                }
                index++;
                for (const clonedValue of clonedValues) {
                  if (
                    updatedRow.store_date_size_color_group_str ==
                    clonedValue.newRow.store_date_size_color_group_str
                  ) {
                    // This is probably not needed, but just in case, and it's not expensive
                    modifyObj(clonedValue.newRowRef, updatedRow);
                    // This is needed to update the sums etc.
                    // This is the expensive part
                    const reactive = index == resp.data.updated.length - 1;
                    modifyObj(clonedValue.oldRowRef, updatedRow, reactive);
                    break;
                  }
                }
              }
              commit("ordergrid/setPaintedOrderRows", painted, { root: true });
            }
            for (const callback of callbacks) {
              callback();
            }
          });
      },
      undo: () => {
        console.debug("Undoing saveQuantity: ", clonedValues);
        const painted = {};
        for (const { newRow, oldRow, newRowRef, oldRowRef } of clonedValues) {
          modifyObj(newRowRef, oldRow);
          modifyObj(oldRowRef, oldRow);
          if (oldRow.quantity) {
            painted[oldRow.store_date_size_color_group_str] = 'not-empty';
          } else {
            painted[oldRow.store_date_size_color_group_str] = 'empty';
          }
        }
        commit("ordergrid/setPaintedOrderRows", painted, { root: true });
        return axios
          .post(`/api/orders/order/${state.pickedOrderId}/update_rows/`, {
            rows: oldRowsToSend,
          })
          .then((resp) => {
            for (const callback of callbacks) {
              callback();
            }
            console.log(resp);
          });
      },
    },
    {
      root: true,
    }
  );
};

export const rowsaver = {
  namespaced: true,
  state: () => ({
    saveValues: {},
    debounceTimeout: null,
    pickedOrderId: null,
  }),
  mutations: {},
  actions: {
    setRowValues(context, { newRow, oldRow, pickedOrderId, changed }) {
      const { state } = context;
      if (
        newRow.quantity == oldRow.quantity ||
        (!newRow.quantity && !oldRow.quantity)
      ) {
        return;
      }
      state.pickedOrderId = pickedOrderId;
      state.saveValues[newRow.store_date_size_color_group_str] = {
        newRow,
        newRowClone: cloneDeep(newRow),
        oldRow,
        oldRowClone: cloneDeep(oldRow),
        changed,
      };
      if (state.debounceTimeout) {
        clearTimeout(state.debounceTimeout);
      }
      state.debounceTimeout = setTimeout(applyChanges, 1000, context);
    },
  },
  getters: {},
};
