<template>
  <div>
    <v-dialog v-model="dialog" width="1200">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Kopioi arvot toiseen varastoon
        </v-card-title>

        <v-card-text>
          <v-select
            v-model="selectedStore"
            :items="
              storeChoices.filter(
                (choice) =>
                  choice.value !== null && choice.value !== storeFilter
              )
            "
            :label="'Varasto'"
          ></v-select>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="save()"> Tallenna </v-btn>
          <v-btn color="danger" text @click="dialog = false"> Peruuta </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn v-if="showButton" @click="dialog = true"> Kopioi </v-btn>
  </div>
</template>

<script>
export default {
  name: "CopyToStoreModal",
  props: [
    "order",
    "storeChoices",
    "colorGroups",
    "storeFilter",
    "showButton",
    "rowsByRowString",
  ],
  data: () => ({
    dialog: false,
    selectedStore: null,
  }),
  methods: {
    save() {
      let rowsToCopy = [];
      let existingRows = [];
      for (const colorGroup of this.colorGroups) {
        for (const sizeGroup of Object.values(
          colorGroup.groups[this.storeFilter].groups
        )) {
          for (const row of Object.values(sizeGroup.groups)) {
            rowsToCopy.push(row);
          }
        }
        for (const sizeGroup of Object.values(
          colorGroup.groups[this.selectedStore].groups
        )) {
          for (const row of Object.values(sizeGroup.groups)) {
            existingRows.push(row);
          }
        }
      }
      // Store originalstate
      const oldRows = this._.cloneDeep(existingRows);
      const newRows = this._.cloneDeep(rowsToCopy);
      const fromStore = this.storeChoices.find(
        (store) => store.value === this.storeFilter
      ).text;
      const toStore = this.storeChoices.find(
        (store) => store.value === this.selectedStore
      ).text;
      const toStoreID = this.selectedStore;
      const colorGroups = this.colorGroups;
      this.$store.commit("addJob", {
        callback: () => {
          for (const row of newRows) {
            const newRow = this._.find(existingRows, {
              color_group_str: row.color_group_str,
              delivery_date: row.delivery_date,
              size: row.size,
            });
            if (newRow) {
              this.$set(newRow, "quantity", row.quantity);
            } else {
              console.log("existingRow not found: ", row);
              // Create it
              // First we find a similar dateGroup
              const storeGroup = this._.find(colorGroups, {
                color_group_str: row.color_group_str,
              }).groups[toStoreID];
              const dateGroupToCopy = this._.cloneDeep(
                Object.values(storeGroup.groups)[0]
              );
              // Now it's cloned, change the date
              dateGroupToCopy.date = row.delivery_date;
              // Update the rows inside the dategroup
              for (const copiedRow of Object.values(dateGroupToCopy.groups)) {
                copiedRow.id = null;
                copiedRow.delivery_date = row.delivery_date;
                if (copiedRow.size === row.size) {
                  // Only update quantity if the size matches
                  // Later on, rest of the rows will be available inside existingRows
                  // For the code block before this else
                  copiedRow.quantity = row.quantity;
                }
                copiedRow.store_date_size_color_group_str = `${copiedRow.store}_${copiedRow.delivery_date}_${copiedRow.size}_${copiedRow.color_group_str}`;
                existingRows.push(copiedRow);
              }
              this.$set(storeGroup.groups, row.delivery_date, dateGroupToCopy);
            }
          }

          console.debug("copyQuantity: ", existingRows);
          return this.axios
            .post(`/api/orders/order/${this.order.id}/update_rows/`, {
              rows: existingRows,
            })
            .then((resp) => {
              console.log("copyQuantity resp:", resp);
              this.$emit("copyComplete", { selectedStore: this.selectedStore });
              this.$store.commit("addAlert", {
                type: "success",
                text: `Kopioitu varastosta ${fromStore} varastoon ${toStore}`,
              });
              // Replace the old row data with new (keep the reference!)
            });
        },
        undo: () => {
          console.debug("Undoing copyQuantity: ", oldRows, existingRows);
          for (const row of oldRows) {
            const newRow = this._.find(existingRows, {
              color_group_str: row.color_group_str,
              delivery_date: row.delivery_date,
              size: row.size,
            });
            this.$set(newRow, "quantity", row.quantity);
          }
          return this.axios
            .post(`/api/orders/order/${this.order.id}/update_rows/`, {
              rows: oldRows,
            })
            .then((resp) => {
              console.log(resp);
            });
        },
      });

      this.dialog = false;
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        return;
      }
    },
  },
};
</script>
