import Vue from "vue";

export const querysave = {
  namespaced: true,
  state: () => ({
    savedQueries: {},
  }),
  mutations: {
    setSavedQuery(state, url) {
      const split = url.split("?");
      const key = split[0];
      const value = split.slice(1).join("?");
      Vue.set(state.savedQueries, key, value);
    },
  },
  actions: {},
  getters: {},
};
