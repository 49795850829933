<template>
  <div
    style="
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      width: 100%;
      height: 100%;
      position: relative;
    "
  >
    <div
      v-if="priceMismatch"
      @click="priceMismatchClick"
      title="Muokkaa kokokohtaisia hintoja"
      style="
        cursor: pointer;
        background-color: #f44336;
        opacity: 0.2;
        position: absolute;
        width: 100%;
        height: 100%;
      "
    ></div>
    <slot
      :newSelectedProduct="newSelectedProduct"
      :updateFunc="saveSelectedProducts"
    ></slot>
  </div>
</template>

<script>
// Wrapper component to edit SelectedProducts values. The modified value to newSelectedProduct is
// Copied over to all selectedProducts. (e.g. newSelectedProduct.color_group_quantity will modify color_group_quantity for them all)
// Use like this:
// <template v-slot="{ newSelectedProduct }">
//   <input v-model="newSelectedProduct.quantity" />
// </template>
//
// SelectedProductsEdit will handle debounce and api call to persist data

import { modifyObj } from "../utils/utils";
import Vue from "vue";

export default {
  name: "SelectedProductsEdit",

  props: {
    selectedProducts: {
      type: Array,
    },
    debounce: {
      type: Number,
      default: 1000,
    },
    choices: {
      type: Object,
    },
  },

  data: () => ({
    newSelectedProducts: [],
  }),

  mounted() {
    this.loadOldSelectedProducts(this.oldSelectedProducts);
  },

  computed: {
    debouncedSaveSelectedProducts() {
      return this._.debounce(this.saveSelectedProducts, this.debounce);
    },
    pickedOrderId() {
      return this.$route.params.orderId;
    },
    oldSelectedProducts() {
      return this.selectedProducts;
    },
    priceMismatch() {
      // Return true if any prices differ between selectedproducts
      const firstSelectedProduct = this.selectedProducts[0];
      if (!firstSelectedProduct) {
        return false;
      }
      const prices = {
        list_price:
          firstSelectedProduct.list_price ||
          firstSelectedProduct.product.list_price ||
          0,
        purchase_price:
          firstSelectedProduct.purchase_price ||
          firstSelectedProduct.product.purchase_price ||
          0,
        campaign_price: firstSelectedProduct.campaign_price || 0,
        net_price:
          firstSelectedProduct.net_price ||
          firstSelectedProduct.product.net_price ||
          0,
      };
      return this.selectedProducts.some((sp) => {
        const sp_prices = {
          list_price: sp.list_price || sp.product.list_price || 0,
          purchase_price: sp.purchase_price || sp.product.purchase_price || 0,
          campaign_price: sp.campaign_price || 0,
          net_price: sp.net_price || sp.product.net_price || 0,
        };
        return Object.keys(prices).some((key) => {
          return prices[key] !== sp_prices[key];
        });
      });
    },
    newSelectedProduct() {
      if (this.newSelectedProducts.length === 0) {
        return {};
      }
      return this.newSelectedProducts[0];
    },
    oldSelectedProduct() {
      if (this.selectedProducts.length === 0) {
        return {};
      }
      return this.selectedProducts[0];
    },
  },

  methods: {
    priceMismatchClick() {
      // Open modal for changing prices
      this.$store.commit("priceMismatchModal/openModal", {
        newSelectedProducts: this.newSelectedProducts,
        updateFunc: this.saveSelectedProducts,
      });
    },
    loadOldSelectedProducts(newOldSelectedProducts) {
      this.newSelectedProducts = this._.cloneDeep(newOldSelectedProducts);
    },
    saveSelectedProducts() {
      let changed = false;
      const fields = [
        "quantity",
        "color_group_quantity",
        "list_price",
        "campaign_price",
        "net_price",
        "comments",
        "campaign_info",
        "issues",
      ];
      if (!this.priceMismatch) {
        for (const field of fields) {
          if (
            this.newSelectedProduct[field] !== this.oldSelectedProduct[field]
          ) {
            // Copy over values to all selectedProducts
            this.newSelectedProducts
              .filter(
                (selectedProduct) =>
                  selectedProduct.id !== this.newSelectedProduct.id
              )
              .forEach((selectedProduct) => {
                selectedProduct[field] = this.newSelectedProduct[field];
              });
            changed = true;
            console.log(
              "saveSelectedProducts newSelectedProduct: ",
              field,
              this.newSelectedProduct[field],
              this.oldSelectedProduct[field]
            );
          }
        }
      } else {
        // Check each selectedproduct for values changed...
        for (const sp of this.selectedProducts) {
          if (changed) {
            break;
          }
          // Find the matching newSelectedProduct
          for (const newSp of this.newSelectedProducts) {
            if (changed) {
              break;
            }
            if (sp.id === newSp.id) {
              // Check each field for changes
              for (const field of fields) {
                if (sp[field] !== newSp[field]) {
                  changed = true;
                  console.log(
                    "saveSelectedProducts newSelectedProduct: ",
                    field,
                    newSp[field],
                    sp[field]
                  );
                  break;
                }
              }
            }
          }
        }
      }
      if (!changed) {
        return;
      }

      const newSelectedProducts = this._.cloneDeep(this.newSelectedProducts);
      const oldSelectedProducts = this._.cloneDeep(this.oldSelectedProducts);

      const newSelectedProductsRef = this.newSelectedProducts;
      const oldSelectedProductsRef = this.oldSelectedProducts;

      this.$store.commit("addJob", {
        callback: () => {
          console.debug("saveSelectedProducts: ", newSelectedProducts);
          modifyObj(oldSelectedProductsRef, newSelectedProducts);
          modifyObj(newSelectedProductsRef, newSelectedProducts);
          return this.axios
            .post(
              `/api/orders/order/${this.pickedOrderId}/update_selectedproducts/`,
              {
                selected_products: newSelectedProducts,
              }
            )
            .then((resp) => {
              console.log(resp);
            });
        },
        undo: () => {
          console.debug("Undoing saveSelectedProducts: ", oldSelectedProducts);

          modifyObj(oldSelectedProductsRef, oldSelectedProducts);
          modifyObj(newSelectedProductsRef, oldSelectedProducts);
          console.debug("oldSelectedProductsRef:", oldSelectedProductsRef);
          console.debug("newSelectedProductsRef:", newSelectedProductsRef);

          return this.axios
            .post(
              `/api/orders/order/${this.pickedOrderId}/update_selectedproducts/`,
              {
                selected_products: oldSelectedProducts,
              }
            )
            .then((resp) => {
              console.log(resp);
            });
        },
      });
    },
  },

  watch: {
    selectedProducts: {
      handler(newOldSelectedProducts) {
        this.loadOldSelectedProducts(newOldSelectedProducts);
      },
      deep: true,
    },
    newSelectedProduct: {
      handler() {
        this.debouncedSaveSelectedProducts();
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
