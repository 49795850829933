<template>
  <div class="order-list">
    <v-data-table :items="changelog" :headers="columns" :items-per-page="100">
      <template v-slot:[`item.handler`]="{ item }">
        {{ (item.handler || {}).username }}
      </template>
    </v-data-table>
    <v-col>
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "OrderHistory",
  components: {},
  data: function () {
    return {
      loading: true,
      changelog: [],
      columns: [
        {
          text: "PVM",
          value: "created",
        },
        {
          text: "Arvo",
          value: "value",
        },
        {
          text: "Käyttäjä",
          value: "handler",
        },
      ],
    };
  },

  mounted() {
    this.fetchLog();
  },

  methods: {
    fetchLog() {
      this.axios
        .get(`/api/orders/order/${this.$route.params.orderId}/changelog/`)
        .then((resp) => {
          this.loading = false;
          this.changelog = resp.data.map((logEntry) => {
            logEntry.created = new Date(logEntry.created).toLocaleString(
              "fi-fi"
            );
            return logEntry;
          });
        });
    },
  },
};
</script>
