<template>
  <span>
    <a @click="setOrderBy(col.value)">{{ col.text }}</a>
    <v-icon v-if="orderBy.includes('-' + col.value)">mdi-chevron-down</v-icon>
    <v-icon v-if="orderBy.includes(col.value)">mdi-chevron-up</v-icon>
  </span>
</template>

<script>
export default {
  name: "OrderByHeader",
  props: {
    col: { type: Object },
    setOrderBy: { type: Function },
    orderBy: { type: Array },
  },
};
</script>
