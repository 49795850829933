<script>
// Includues helper functions for summming row prices etc.
export default {
  name: "ColorGroupCalculations",
  mounted() {},
  methods: {
    getRowPrices(row, overrideQuantity) {
      const quantity = overrideQuantity || parseInt(row.quantity) || 0;
      const selectedproduct = this.order.selectedproducts[row.selectedproduct];
      if (!selectedproduct) {
        console.log("ERROR IN ", { ...row });
        return {
          quantity: 0,
          list_price: 0,
          purchase_price: 0,
          net_price: 0,
          profit: 0,
        };
      }
      const purchase_price = selectedproduct.purchase_price;
      const net_price =
        selectedproduct.net_price ||
        selectedproduct.product.net_price ||
        purchase_price;
      const list_price =
        selectedproduct.campaign_price ||
        selectedproduct.list_price ||
        selectedproduct.product.list_price;
      return {
        quantity: quantity,
        list_price: quantity * list_price,
        purchase_price: quantity * purchase_price,
        net_price: quantity * net_price,
        profit: quantity * list_price - quantity * net_price,
      };
    },
    initRowSum() {
      return {
        quantity: 0,
        list_price: 0,
        purchase_price: 0,
        net_price: 0,
        profit: 0,
      };
    },
    addRowPrices(row, ret) {
      if (ret == undefined) {
        ret = this.initRowSum();
      }
      if (row == undefined) {
        return ret;
      }
      const rowPrices = this.getRowPrices(row);

      ret.quantity += rowPrices.quantity;
      ret.purchase_price += rowPrices.purchase_price;
      ret.net_price += rowPrices.net_price;
      ret.list_price += rowPrices.list_price;
      ret.profit += rowPrices.profit;

      return ret;
    },
    getColorGroupPrices(colorGroup) {
      // Get basic pricing values for a colorGroup (no sums or anything!)
      for (const store of Object.values(colorGroup.groups)) {
        for (const dateGroup of Object.values(store.groups)) {
          for (const row of Object.values(dateGroup.groups)) {
            const rowPrices = this.getRowPrices(row, 1);
            rowPrices.profit_percentage =
              (1 - rowPrices.net_price / rowPrices.list_price) * 100.0;
            return rowPrices;
          }
        }
      }
    },
    getRowTotals(dateGroup) {
      let ret = this.initRowSum();
      for (const row of Object.values(dateGroup.groups)) {
        ret = this.addRowPrices(row, ret);
      }
      return ret;
    },
    getRowGlobalTotal(dateGroup) {
      let ret = this.initRowSum();
      const justARow = Object.values(dateGroup.groups)[0];
      for (const store of Object.values(
        this.order.groups[justARow.color_group_str].groups
      )) {
        if (!(justARow.delivery_date in store.groups)) {
          continue;
        }
        for (const row of Object.values(
          store.groups[justARow.delivery_date].groups
        )) {
          ret = this.addRowPrices(row, ret);
        }
      }
      return ret;
    },
    getSizeGlobalTotals(colorGroup, size) {
      let ret = this.initRowSum();
      for (const store of Object.values(colorGroup.groups)) {
        for (const dateGroup of Object.values(store.groups)) {
          const row = dateGroup.groups[size.id];
          ret = this.addRowPrices(row, ret);
        }
      }
      return ret;
    },
    getColorGroupGlobalTotals(colorGroup) {
      let ret = this.initRowSum();
      for (const store of Object.values(colorGroup.groups)) {
        for (const dateGroup of Object.values(store.groups)) {
          for (const row of Object.values(dateGroup.groups)) {
            ret = this.addRowPrices(row, ret);
          }
        }
      }
      return ret;
    },
  },
  computed: {},
  watch: {},
};
</script>
