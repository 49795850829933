<template>
  <div id="painted-area" :style="styling"></div>
</template>

<script>
export default {
  name: "ShiftPainter",

  props: {
    topOffset: {
      type: Number,
      default: 0,
    },
    leftOffset: {
      type: Number,
      default: 0,
    },
    relativePosition: {
      type: Boolean,
      default: false,
    },
  },

  beforeDestroy() {
    this.$store.commit("ordergrid/setShiftSelectStartCell", null);
    this.$store.commit("ordergrid/setShiftSelectEndCell", null);
  },

  computed: {
    paintedCells() {
      if (!this.endCell) {
        return [];
      }
      const ret = {};
      const topLeft = this.edgeCells.topLeft;

      const minX = this.edgeCells.minX;
      const maxX = this.edgeCells.maxX;
      const minY = this.edgeCells.minY;
      const maxY = this.edgeCells.maxY;
      const trList = this.edgeCells.trList;

      let relativeY = 0;
      for (let y = minY; y <= maxY; y++) {
        let relativeX = 0;
        const row = trList[y];
        for (let x = minX; x <= maxX; x++) {
          const cell = row.childNodes[x];
          ret[`${relativeX},${relativeY}`] = cell;
          relativeX++;
        }
        relativeY++;
      }

      return ret;
    },
    edgeCells() {
      const ret = {
        topLeft: null,
        bottomRight: null,
        minX: null,
        maxX: null,
        minY: null,
        maxY: null,
        trList: null,
      };
      const startCell = this.startCell || this.endCell;

      ret.trList = [];
      try {
        let tableEl = startCell.parentElement.parentElement;
        while (tableEl.tagName !== "TABLE") {
          tableEl = tableEl.parentElement;
        }
        for (const tmpTR of tableEl.querySelectorAll("tr")) {
          ret.trList.push(tmpTR);
        }
      } catch (err) {
        console.log(err);
        return ret;
      }

      const startRow = startCell.parentElement;
      let startX = [].slice
        .call(startCell.parentElement.childNodes)
        .indexOf(startCell);
      let startY = [].slice.call(ret.trList).indexOf(startRow);

      const endCell = this.endCell;
      const endRow = endCell.parentElement;
      let endX = [].slice
        .call(endCell.parentElement.childNodes)
        .indexOf(endCell);
      let endY = [].slice.call(ret.trList).indexOf(endRow);

      ret.minX = Math.min(startX, endX);
      ret.maxX = Math.max(startX, endX);
      ret.minY = Math.min(startY, endY);
      ret.maxY = Math.max(startY, endY);

      if (startY < endY) {
        ret.topLeft = startRow.childNodes[ret.minX];
        ret.bottomRight = endRow.childNodes[ret.maxX];
      } else {
        ret.topLeft = endRow.childNodes[ret.minX];
        ret.bottomRight = startRow.childNodes[ret.maxX];
      }
      return ret;
    },
    startCell() {
      return this.$store.state.ordergrid.shiftSelectStartCell;
    },
    endCell() {
      return this.$store.state.ordergrid.shiftSelectEndCell;
    },
    styling() {
      if (
        !this.startCell ||
        !this.endCell ||
        this.startCell == this.endCell ||
        !this.edgeCells.topLeft
      ) {
        return {
          display: "none",
        };
      }
      const startRect = this.edgeCells.topLeft.getBoundingClientRect();
      const endRect = this.edgeCells.bottomRight.getBoundingClientRect();

      let offsetLeft = startRect.left + document.documentElement.scrollLeft;
      let offsetTop = startRect.top + document.documentElement.scrollTop;
      if (this.relativePosition) {
        offsetLeft = this.edgeCells.topLeft.offsetLeft;
        offsetTop = this.edgeCells.topLeft.offsetTop;
      }

      const left = offsetLeft + this.leftOffset;
      const top = offsetTop + this.topOffset;
      const width = Math.abs(startRect.left - endRect.left) + endRect.width;
      const height = Math.abs(startRect.top - endRect.top) + endRect.height;
      return {
        left: left + "px",
        top: top + "px",
        width: width + "px",
        height: height + "px",
      };
    },
  },

  methods: {
    sumPaintedCells() {
      let sum = 0;
      for (const cell of Object.values(this.paintedCells)) {
        try {
          const cellVal = parseFloat(
            cell.querySelector("input").parentElement.__vue__.formattedValue
          );
          if (!isNaN(cellVal)) {
            sum += cellVal;
          }
        } catch (err) {
          console.log(err);
        }
      }
      this.$store.commit("ordergrid/setShiftSelectSum", sum);
      return sum;
    },
    handleAction(action) {
      const paintedCells = this.paintedCells;
      if (action === "copy" || action === "cut") {
        const copiedRows = {};
        for (const coords in paintedCells) {
          let val =
            paintedCells[coords].querySelector("input").parentElement.__vue__
              .formattedValue;
          if (!val) {
            // Try to just get input value
            val = paintedCells[coords].querySelector("input").value;
          }
          const intCoords = coords.split(",").map((x) => parseInt(x));
          copiedRows[intCoords[1]] = copiedRows[intCoords[1]] || {};
          copiedRows[intCoords[1]][intCoords[0]] = val;
        }
        let copiedValuesStr = "";
        for (const y in Object.keys(copiedRows).sort()) {
          for (const x in Object.keys(copiedRows[y]).sort()) {
            copiedValuesStr += `${copiedRows[y][x]}\t`;
          }
          // Remove last tab
          copiedValuesStr = copiedValuesStr.slice(0, -1);
          copiedValuesStr += "\n";
        }
        console.log(copiedValuesStr);
        if (!navigator.clipboard) {
          alert("Kopiointi ei onnistu!");
          return;
        }
        navigator.clipboard.writeText(copiedValuesStr);
      }
      if (action === "delete" || action === "cut") {
        console.log("Got delete");
        for (const coords in paintedCells) {
          try {
            paintedCells[coords]
              .querySelector("input")
              .parentElement.__vue__.clear();
          } catch (err) {
            console.log(err);
          }
        }
      }
      if (action === "input") {
        const intVal = this.$store.state.ordergrid.shiftSelectIntVal;
        for (const coords in paintedCells) {
          try {
            paintedCells[coords]
              .querySelector("input")
              .parentElement.__vue__.setValue(intVal);
          } catch (err) {
            console.log(err);
          }
        }
      }
      if (action === "paste") {
        console.log("paste");
        if (!navigator.clipboard) {
          alert("Liittäminen ei onnistu!");
          return;
        }
        navigator.clipboard.readText().then((text) => {
          const rows = text
            .split("\n")
            .filter((x) => x)
            .map((x) => x.split("\t"));
          const startCell = this.edgeCells.topLeft;
          const endCell = this.edgeCells.bottomRight;
          const minX = this.edgeCells.minX;
          const minY = this.edgeCells.minY;
          const trList = this.edgeCells.trList;
          let maxY = this.edgeCells.maxY;
          let maxX = this.edgeCells.maxX;

          if (startCell == endCell) {
            // Only a single cell selected, paste whole thing
            maxY = minY + rows.length - 1;
            maxX = minX + rows[0].length - 1;
          }

          let sizeTDClassRequired = false;
          if (startCell.classList.contains("size-td")) {
            sizeTDClassRequired = true;
          }

          let relativeY = 0;
          console.log(maxX, maxY);
          let timeout = 0;
          for (let y = minY; y <= maxY; y++) {
            let relativeX = 0;
            const row = trList[y];
            for (let x = minX; x <= maxX; x++) {
              const cell = row.childNodes[x];
              if (
                cell.classList.contains("keyboard-navigation") &&
                (!sizeTDClassRequired || cell.classList.contains("size-td"))
              ) {
                const inputEl = cell.querySelector("input");
                if (inputEl) {
                  const myinputEl = inputEl.parentElement.__vue__;
                  const myVal = (rows[relativeY] || {})[relativeX] || "";
                  setTimeout(() => {
                    myinputEl.setValue(myVal);
                  }, timeout);
                  timeout += 5;
                }
              }
              relativeX++;
            }
            relativeY++;
          }
        });
      }

      this.$store.commit("ordergrid/setShiftSelectAction", null);
    },
  },

  watch: {
    "$store.state.ordergrid.shiftSelectAction"(newAction) {
      if (!newAction) {
        return;
      }
      this.handleAction(newAction);
    },
    "$store.state.ordergrid.shiftSelectEndCell"() {
      this.$nextTick(() => {
        this.sumPaintedCells();
      });
    },
  },
};
</script>

<style>
#painted-area {
  position: absolute;
  z-index: 100;
  background-color: rgba(183, 255, 0, 0.2);
  border: 2px solid rgba(20, 59, 255, 0.754);
  pointer-events: none;
}
</style>
