var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"parse-file"},[_c('v-card',[_c('v-card-title',{staticClass:"parse-file-header headline grey lighten-2"},[(Object.values(_vm.fieldMappings).length == _vm.fieldsToMap.length)?_c('v-btn',{on:{"click":function($event){return _vm.startParse()}}},[_vm._v("Aloita parse")]):_vm._e(),(_vm.loading)?_c('span',[_vm._v("Ladataan....")]):(_vm.headerIndex)?_c('div',{staticClass:"draggable-fields"},_vm._l((_vm.fieldsToMap),function(field){return _c('div',{key:field.name,staticClass:"draggable-field",class:{ used: field.name in _vm.fieldMappings },attrs:{"draggable":"true"},on:{"dragstart":function($event){return _vm.startDragField(field)},"dragend":function($event){return _vm.stopDragField(field)},"mouseenter":function($event){_vm.highlightedColumn = _vm.fieldMappings[field.name]},"mouseleave":function($event){_vm.highlightedColumn = null}}},[_vm._v(" "+_vm._s(field.verbose_name)+" ")])}),0):_vm._e()],1),_c('v-card-text',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.sheet),function(row,index){return _c('tr',{key:`row-${index}`,class:{
                'header-row': index === _vm.headerIndex,
                hidden: index < _vm.headerIndex,
              }},[_c('td',[(_vm.headerIndex === null)?_c('v-btn',{on:{"click":function($event){_vm.headerIndex = index}}},[_vm._v("Otsikko")]):_vm._e()],1),_vm._l((row),function(value,valueIndex){return _c('td',{key:`value-${valueIndex}`,class:{
                  'drag-target': !!_vm.draggingField && index === _vm.headerIndex,
                  'drag-hover': _vm.dragHoverIndex === valueIndex,
                  highlighted:
                    index === _vm.headerIndex && _vm.highlightedColumn === valueIndex,
                  mapped:
                    index === _vm.headerIndex &&
                    Object.values(_vm.fieldMappings).includes(valueIndex),
                },on:{"drop":function($event){return _vm.onDropField(index, valueIndex)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();_vm.dragHoverIndex = valueIndex},"dragleave":function($event){$event.preventDefault();if (_vm.dragHoverIndex === valueIndex) {
                    _vm.dragHoverIndex = null;
                  }}}},[_vm._v(" "+_vm._s(value)+" ")])})],2)}),0)]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }