<template>
  <div>
    <slot :newRow="newRow" :updateFunc="debouncedSaveQuantity"></slot>
  </div>
</template>

<script>
// Wrapper component to edit row values.
// Use like this:
// <template v-slot="{ newRow, updateFunc }">
//   <input v-model="newRow.quantity" />
// </template>
//
// OrderRowEdit will handle debounce and api call to persist data

import { modifyObj } from "../utils/utils";

export default {
  name: "OrderRowEdit",

  props: {
    row: {
      type: Object,
    },
    reactiveRow: {
      type: Object,
    },
    debounce: {
      type: Number,
      default: 1000,
    },
  },

  data: () => ({
    newRow: {},
  }),

  mounted() {
    this.newRow = this._.cloneDeep(this.oldRow);
  },

  computed: {
    oldRow() {
      return this.row;
    },
    pickedOrderId() {
      return this.$route.params.orderId;
    },
  },

  methods: {
    debouncedSaveQuantity() {
      console.log("debouncedSaveQuantity: ", this.newRow.quantity);
      this.$store.dispatch("rowsaver/setRowValues", {
        newRow: this.newRow,
        oldRow: this.oldRow,
        pickedOrderId: this.pickedOrderId,
        changed: () => {
          this.$emit("changed");
        },
      });
    },
  },

  watch: {
    reactiveRow: {
      handler(newValue) {
        this.newRow.quantity = newValue.quantity;
      },
      deep: true,
    },
    "newRow.quantity"(newQuantity) {
      if (
        newQuantity != "DELETE" &&
        (newQuantity || 0) != (this.oldRow.quantity || 0)
      ) {
        this.debouncedSaveQuantity();
      }
    },
  },
};
</script>

<style scoped></style>
