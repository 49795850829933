<template>
  <div class="order-list">
    <v-row>
      <v-col>
        <v-select
          label="Myymälä"
          v-model="store"
          :items="
            (choices.stores || []).map((row) => ({
              value: row.id,
              text: row.name,
            }))
          "
        ></v-select>
      </v-col>
      <v-col>
        <v-date-picker
          v-model="dateRange"
          :first-day-of-week="1"
          range
          show-adjacent-months
        ></v-date-picker>
      </v-col>
      <v-col>
        <p>
          Valittu viikot {{ dateRangeAsWeekNumbers }} (Sesongit
          {{ dateRangeAsSeasonStrings }})
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <child-order-table
          v-if="!loading"
          :orders="orders"
          :store="store"
          :choices="choices"
        >
        </child-order-table>
      </v-col>
    </v-row>
    <v-col>
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-col>
  </div>
</template>

<script>
import ChoicesOrderVue from "../mixins/ChoicesOrder.vue";
import ChildOrderTable from "../components/ChildOrderTable.vue";

export default {
  name: "WeeklyOrders",
  mixins: [ChoicesOrderVue],
  components: {
    ChildOrderTable,
  },
  data: function () {
    return {
      loading: true,
      store: null,
      orders: [],
      dateRange: this.initialDateRange(),
      rowColumns: [
        {
          text: "EAN",
          value: "ean",
        },
        {
          text: "Tuotemalli",
          value: "name",
        },
        {
          text: "Väri",
          value: "color__name",
        },
        {
          text: "Koko",
          value: "size",
        },
        {
          text: "kpl",
          value: "quantity",
        },
      ],
    };
  },

  mounted() {
    this.fetchOrders();
  },

  computed: {
    columns() {
      const ret = [
        {
          text: "Viikko",
          value: "delivery_date",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Metso-tunnus",
          value: "id",
        },
        {
          text: "Tilausnumero",
          value: "ordernumber",
        },
        {
          text: "Nimi",
          value: "name",
        },
        {
          text: "Toimittaja",
          value: "supplier_id",
        },
        {
          text: "Tuotemalleja",
          value: "total_color_groups",
        },
        {
          text: "Tuotteita kpl",
          value: "total_quantity",
        },
      ];
      if (!this.store) {
        ret.push({
          text: "Myymälä",
          value: "store_id",
        });
      }
      return ret;
    },
    dateRangeAsWeekNumbers() {
      // Comma separated list of week numbers
      let ret = "";
      let currDate = this.dateRange[0];
      while (currDate <= this.dateRange[1]) {
        ret += this.$date(currDate).week() + ",";
        currDate = this.$date(currDate)
          .add(1, "week")
          .toISOString()
          .split("T")[0];
      }
      return ret.slice(0, -1);
    },
    dateRangeAsSeasonStrings() {
      let ret = new Set();
      let currDate = this.dateRange[0];
      const getSeasonStrings = (dateStr) => {
        // fall and winter = FW23
        // spring and summer = SS23
        const date = this.$date(dateStr);
        const year = date.year();
        const month = date.month();
        const seasonStrings = new Set();
        if (month < 3) {
          // FW previous year
          seasonStrings.add(`FW` + `${year - 1}`.substring(2, 4));
        }
        if (month <= 6) {
          // SS current year
          seasonStrings.add(`SS` + `${year}`.substring(2, 4));
        }
        if (month >= 6) {
          // FW current year
          seasonStrings.add(`FW` + `${year}`.substring(2, 4));
        }
        if (month > 10) {
          // SS next year
          seasonStrings.add(`SS` + `${year + 1}`.substring(2, 4));
        }
        return seasonStrings;
      };
      while (currDate <= this.dateRange[1]) {
        getSeasonStrings(currDate).forEach((sString) => {
          ret.add(sString);
        });
        currDate = this.$date(currDate)
          .add(1, "week")
          .toISOString()
          .split("T")[0];
      }
      return Array.from(ret).join(",");
    },
  },

  watch: {
    dateRange() {
      this.fixDateRange();
      this.fetchOrders();
    },
    store() {
      this.fetchOrders();
    },
  },

  methods: {
    fixDateRange() {
      // if prev date is after next date, swap them
      if (this.dateRange[0] > this.dateRange[1]) {
        this.dateRange = [this.dateRange[1], this.dateRange[0]];
      }
    },
    initialDateRange() {
      // Today to 7 days in the future

      return [
        new Date().toISOString().split("T")[0],
        new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
          .toISOString()
          .split("T")[0],
      ];
    },
    fetchOrders() {
      if (this.dateRange.length < 2) {
        return;
      }
      this.loading = true;
      let query = "?";
      query += `weeks=${this.dateRangeAsWeekNumbers}`;
      query += `&seasons=${this.dateRangeAsSeasonStrings}`;
      if (this.store) {
        query += `&store_id=${this.store}`;
      }
      this.axios.get("/api/orders/child_orders/" + query).then((resp) => {
        this.loading = false;
        this.orders = resp.data.map((order) => {
          return order;
        });
      });
    },
  },
};
</script>
