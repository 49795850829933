<template>
  <div class="wrapper">
    <v-dialog v-model="modal" width="600">
      <v-card>
        <v-card-title>
          Muokkaa kokokohtaisia hintoja tuotteelle {{ productName }}
        </v-card-title>
        <v-card-text>
          <table>
            <thead>
              <tr>
                <th>Väri/Koko</th>
                <th v-for="priceCol in priceColumns" :key="priceCol.slug">
                  {{ priceCol.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="sp in newSelectedProducts" :key="sp.product.id">
                <td>{{ spVerboseName(sp) }}</td>
                <td v-for="priceCol in priceColumns" :key="priceCol.slug">
                  <cell-input
                    ref="inputs"
                    :allowTab="true"
                    size="3"
                    type="text"
                    :required="true"
                    :disabled="!!priceCol.disabled"
                    v-model="sp[priceCol.slug]"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="save"> Tallenna </v-btn>
          <v-btn color="danger" text @click="modal = false"> Sulje </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CellInput from "../components/CellInput.vue";

export default {
  name: "PriceMismatchModal",

  components: { CellInput },

  props: {},

  data: () => ({}),

  computed: {
    modal: {
      get() {
        return this.$store.state.priceMismatchModal.showModal;
      },
      set() {
        this.$store.commit("priceMismatchModal/closeModal");
      },
    },
    newSelectedProducts() {
      return this.$store.state.priceMismatchModal.newSelectedProducts;
    },
    priceColumns() {
      return [
        {
          name: "NETTO",
          slug: "purchase_price",
          disabled: true,
        },
        {
          name: "ENETTO",
          slug: "net_price",
        },
        {
          name: "Suositushinta",
          slug: "list_price",
        },
        {
          name: "Kampanjahinta",
          slug: "campaign_price",
        },
      ];
    },
    productName() {
      const sp = (this.newSelectedProducts || [])[0];
      if (!sp) {
        return "";
      }
      const p = sp.product;
      return `${p.name}`;
    },
  },

  methods: {
    save() {
      this.$store.state.priceMismatchModal.updateFunc();
      this.modal = false;
    },
    spVerboseName(sp) {
      const p = sp.product;
      return `${p.color__name} ${p.size__name}`;
    },
  },

  watch: {},
};
</script>

<style scoped lang="scss">
td {
  border: 1px solid black;
  padding: 0 0.5rem;
}
td > * {
  margin: 0.5rem 0;
}
td:first-child {
  border: none;
}
</style>
