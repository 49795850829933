<template>
  <v-container v-if="!order"> Ladataan... </v-container>
  <v-container v-else-if="!orderIsSent">
    <v-row>
      <v-col>
        <h4>Tilaus #{{ $route.params.orderId }}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        Tilausta ei ole merkattu lähetetyksi. Merkitse tilaus lähetetyksi ensin!
        Varmista että sesonki on valittu.
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-row v-if="!order || loading">
      <v-col>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h4>Tilaus #{{ $route.params.orderId }}</h4>
      </v-col>
      <v-col>
        <v-select
          v-if="order && orderIsSent"
          :items="confirmWithChildrenChoices"
          item-text="name"
          item-value="id"
          label="Vahvista samalla nämä tilaukset"
          v-model="extraOrders"
          @change="fetchOrder()"
          multiple
        >
        </v-select>
      </v-col>
      <v-col>
        <a
          v-for="order in orders"
          :key="order.id"
          :href="`/order/${order.id}/edit/`"
          target="_blank"
        >
          {{ `/order/${order.id}/edit/` }} ({{ order.name }})
        </a>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          v-if="!orderIsFixing"
          @click="enableEditMode"
          :disabled="loading || orderIsExported"
          >Siirry muokkaustilaan</v-btn
        >
        <v-btn v-else @click="disableEditMode" :disabled="loading"
          >Pois muokkaustilasta</v-btn
        >
      </v-col>
      <v-col>
        <v-select
          v-model="teksoSplitMode"
          :label="
            !orderIsConfirmed
              ? 'Tilauksen jako (Merkkaa ensin vahvistetuksi)'
              : 'Tilauksen jako'
          "
          :items="[
            { value: 'store', text: 'Varasto' },
            { value: 'store_date', text: 'Varasto+Toimitusvko' },
          ]"
          :disabled="!orderIsConfirmed"
        ></v-select>
        <v-btn @click="reSplitOrder" :disabled="loading || !orderIsConfirmed"
          >Jaa</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <child-order-table
        :orders="childOrders"
        :choices="choices"
        :update-order-number="updateOrderNumber"
        :order="order"
      ></child-order-table>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          :disabled="orderIsConfirmed || orderIsExported || loading"
          @click="markAsConfirmed"
          >Merkkaa vahvistetuksi</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="testOrderCrotools" :disabled="loading || orderIsFixing"
          >Lataa TEKSO-json</v-btn
        >
      </v-col>
      <v-col>
        <v-btn
          @click="exportOrderCrotools()"
          :disabled="loading || !orderIsConfirmed || orderIsExported"
          >Vie TEKSOON</v-btn
        >
      </v-col>
      <v-col>
        <v-btn @click="exportProductsCrotools()"
          >Vie toimittajan tuotelista CSV</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ChoicesOrderVue from "../mixins/ChoicesOrder.vue";
import ChildOrderTable from "../components/ChildOrderTable.vue";

export default {
  name: "ConfirmOrder",
  components: { ChildOrderTable },
  mixins: [ChoicesOrderVue],
  data: () => ({
    orders: [],
    childOrders: [],
    storeVersion: false,
    loading: false,
    lastExportTime: null,
    confirmWithChildrenChoices: [],
    teksoSplitMode: null,
  }),
  mounted() {
    this.orders = [
      {
        id: this.$route.params.orderId,
      },
    ];
    this.fetchOrder().then(() => {
      this.loadQueryParams();
      this.$nextTick(() => {
        this.getConfirmWithChildrenChoices();
        if (this.orders.length > 1) {
          this.fetchOrder();
        }
      });
    });
  },
  methods: {
    loadQueryParams() {
      const params = new URLSearchParams(window.location.search);
      const extraOrders = params.getAll("extra_orders[]") || [];
      if (extraOrders.length > 0) {
        this.$set(
          this,
          "extraOrders",
          extraOrders.map((id) => parseInt(id))
        );
      }
    },
    saveQueryParams() {
      const params = new URLSearchParams();
      for (const extraOrder of this.extraOrders) {
        params.append("extra_orders[]", `${extraOrder}`);
      }
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${params.toString()}`
      );
    },
    async getConfirmWithChildrenChoices() {
      this.confirmWithChildrenChoices = (
        await this.axios
          .get(`/api/orders/orders/?supplier_id=${this.order.supplier}`)
          .then((resp) => resp.data)
      )
        .filter((order) => this.getOrderIsSent(order))
        .filter((order) => order.id != this.order.id)
        .map((order) => {
          return {
            id: order.id,
            name: `#${order.id} - ${order.name}`,
          };
        });
    },
    async saveOrders(values) {
      if (!values) {
        return;
      }
      this.loading = true;
      for (const order of this.orders) {
        await this.axios.post(`/api/orders/order/${order.id}/edit/`, values);
      }
      await this.fetchOrder();
    },
    reSplitOrder() {
      if (!confirm("Huom! Jako tyhjentää mahdolliset syötetyt tilausnumerot")) {
        return;
      }
      this.saveOrders({
        tekso_split_mode: this.teksoSplitMode,
        force_split: true,
      });
    },
    enableEditMode() {
      this.saveOrders({ status: "fixing" });
    },
    disableEditMode() {
      this.saveOrders({ status: "sent" });
    },
    async fetchOrder() {
      this.loading = true;
      (this.order || {}).status = null;
      this.childOrders = [];
      const orderFetches = [];
      for (const order of this.orders) {
        orderFetches.push(
          this.axios.get("/api/orders/order/" + order.id + "/edit/")
        );
      }
      const orderFetchesResp = await Promise.all(orderFetches);
      console.log(orderFetchesResp);
      let index = -1;
      for (const resp of orderFetchesResp) {
        index++;
        this.$set(this.orders, index, resp.data);
        this.teksoSplitMode = resp.data.tekso_split_mode;
      }
      const childOrderFetches = [];
      for (const order of this.orders) {
        index++;
        if (this.getOrderIsSent(this.orders[index])) {
          childOrderFetches.push(
            this.axios.get(`/api/orders/child_orders/?order_id=${order.id}`)
          );
        }
      }
      const childOrderFetchesResp = await Promise.all(childOrderFetches);
      console.log(childOrderFetchesResp);
      for (const childOrderFetchResp of childOrderFetchesResp) {
        const resp = childOrderFetchResp;
        resp.data.forEach((childOrder) => {
          this.childOrders.push(childOrder);
        });
        this.childOrders.forEach((childOrder) => {
          this.$set(childOrder, "ordernumber_editable", true);
        });
      }
      this.loading = false;
    },
    async updateOrderNumber(passedChildOrder, newOrderNumber) {
      this.$set(passedChildOrder, "ordernumber_editable", false);
      await this.axios
        .post(`/api/orders/child_order/${passedChildOrder.id}/edit/`, {
          ordernumber: newOrderNumber,
        })
        .then((resp) => {
          const message = (resp.data || {}).message || "";
          this.$store.commit("addAlert", {
            type: "success",
            text: `Tilausnumero päivitetty onnistuneesti! ${message}`,
          });
          this.$set(
            passedChildOrder,
            "ordernumber",
            resp.data.data.ordernumber
          );
          const tmpChildOrders = this.childOrders;
          this.$set(this, "childOrders", []);
          this.$nextTick(() => {
            this.$set(
              this,
              "childOrders",
              tmpChildOrders.map((childOrder) => {
                if (childOrder.id === passedChildOrder.id) {
                  childOrder.ordernumber = resp.data.data.ordernumber;
                }
                return childOrder;
              })
            );
          });
        })
        .finally(() => {
          this.$set(passedChildOrder, "ordernumber_editable", true);
        });
    },
    saveTemplateAsFile(filename, dataObjToWrite, datatype = "text/json") {
      let blob;
      if (datatype == "text/json") {
        blob = new Blob([JSON.stringify(dataObjToWrite)], {
          type: datatype,
        });
      } else {
        blob = new Blob([dataObjToWrite], {
          type: datatype,
        });
      }
      const link = document.createElement("a");

      link.download = filename;
      link.href = window.URL.createObjectURL(blob);
      link.dataset.downloadurl = [datatype, link.download, link.href].join(":");

      const evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });

      link.dispatchEvent(evt);
      link.remove();
    },
    testOrderCrotools() {
      this.exportOrderCrotools(true);
    },
    exportOrderCrotools(isTest = false) {
      this.loading = true;
      this.axios
        .post("/api/orders/order/" + this.$route.params.orderId + "/export/", {
          export_type: "crotools",
          export_with_children: this.extraOrders,
          download_only: isTest,
        })
        .then((resp) => {
          console.log(resp);
          const jsonData = resp.data.data;
          if (resp.data.ftp) {
            if (resp.data.ftp.error) {
              this.$store.commit("addAlert", {
                type: "error",
                text: `${resp.data.ftp.error}`,
              });
            } else if (resp.data.ftp.info) {
              this.order.status = "exported";
              this.orders.forEach((order) => {
                this.$set(order, "status", "exported");
              });
              this.childOrders.forEach((childOrder) => {
                this.$set(childOrder, "status", "exported");
              });
              this.$store.commit("addAlert", {
                type: "success",
                text: `${resp.data.ftp.info}`,
              });
            }
          } else {
            this.saveTemplateAsFile(`crotools_${this.order.id}.json`, jsonData);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    exportProductsCrotools() {
      const vendorId = prompt("Anna toimittajan TEKSO-ID");
      this.loading = true;
      this.axios
        .post("/api/orders/order/" + this.$route.params.orderId + "/export/", {
          export_type: "crotools_products",
          vendor_id: vendorId,
        })
        .then((resp) => {
          console.log(resp);
          const jsonData = resp.data.data;
          this.saveTemplateAsFile(
            `EAN_VENDOR_${vendorId}.csv`,
            jsonData,
            "text/csv"
          );
          if (resp.data.ftp) {
            if (resp.data.ftp.error) {
              this.$store.commit("addAlert", {
                type: "error",
                text: `${resp.data.ftp.error}`,
              });
            } else if (resp.data.ftp.info) {
              this.$store.commit("addAlert", {
                type: "success",
                text: `${resp.data.ftp.info}`,
              });
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    markAsConfirmed() {
      if (
        !confirm(
          "Olethan tarkistanut että vahvistetut tuotteet täsmäävät määrävalintoihin?"
        )
      ) {
        return;
      }
      this.saveOrders({ status: "confirmed" });
    },
    getOrderIsSent(order) {
      if (!order) {
        return true;
      }
      if (!order.season_str) {
        return false;
      }
      if (
        ["sent", "exported", "confirmed", "cancelled", "fixing"].includes(
          order.status
        )
      ) {
        return true;
      }
      return false;
    },
  },
  computed: {
    order() {
      const ret = this.orders.find((order) => {
        return parseInt(order.id) === parseInt(this.$route.params.orderId);
      });
      if (!ret || !ret.status) {
        return null;
      }
      return ret;
    },
    extraOrders: {
      get() {
        return this.orders
          .filter((order) => {
            return order.id !== (this.order || {}).id;
          })
          .map((order) => order.id);
      },
      set(newExtraOrders) {
        const cleanExtraOrders = newExtraOrders.map((orderId) => {
          return {
            id: orderId,
          };
        });
        this.orders.splice(
          0,
          this.orders.length,
          this.order,
          ...cleanExtraOrders
        );
        this.saveQueryParams();
      },
    },
    orderIsSent() {
      return this.getOrderIsSent(this.order);
    },
    orderIsConfirmed() {
      for (const order of this.orders) {
        if (!order) {
          return false;
        }
        if (["confirmed"].includes(order.status)) {
          return true;
        }
      }
      return false;
    },
    orderIsExported() {
      for (const order of this.orders) {
        if (!order) {
          return false;
        }
        if (["exported"].includes(order.status)) {
          return true;
        }
      }
      return false;
    },
    orderIsFixing() {
      for (const order of this.orders) {
        if (!order) {
          return false;
        }
        if (!["fixing"].includes(order.status)) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style scoped lang="scss"></style>
