<script>
// Save selected variables into query params
// Uses variable querySave with a list of attribute names
export default {
  name: "QuerySave",
  data: () => ({
    unwatchList: [],
  }),
  mounted() {
    this.loadQueryParams();
    this.createWatchers();
  },
  beforeDestroy() {
    this.removeWatchers();
  },
  methods: {
    loadQueryParams() {
      const found = this.loadSearch(window.location.search);
      const key = window.location.href.split("?")[0];
      console.log("found: ", found);

      if (!found && this.$store.state.querysave.savedQueries[key]) {
        const foundStored = this.loadSearch(
          this.$store.state.querysave.savedQueries[key]
        );
        if (foundStored) {
          this.saveQueryParams();
        }
      }
    },
    loadSearch(searchString) {
      let urlParams = new URLSearchParams(searchString);
      let found = false;
      for (const [key, value] of urlParams) {
        if (!this.querySave.includes(key)) {
          console.log(key, "not in ", this.querySave);
          continue;
        }
        found = true;
        console.log(key + ": " + value);
        let parsedVal = value;
        parsedVal = JSON.parse(parsedVal);
        this.$set(this, key, parsedVal);
      }
      return found;
    },
    saveQueryParams() {
      console.log("saveQueryParams");
      // Build params
      var newRelativePathQuery = new URL(
        `${window.location.protocol}//${window.location.host}${window.location.pathname}`
      );
      let params = newRelativePathQuery.searchParams;
      for (const attr of this.querySave) {
        params.append(attr, JSON.stringify(this[attr]));
      }

      this.$store.commit(
        "querysave/setSavedQuery",
        newRelativePathQuery.toString()
      );

      history.pushState(null, "", newRelativePathQuery.toString());
    },
    createWatchers() {
      for (const attr of this.querySave) {
        this.unwatchList.push(
          this.$watch(
            attr,
            (newValue) => {
              this.debouncedSaveQueryParams();
            },
            { deep: true }
          )
        );
      }
    },
    removeWatchers() {
      for (const unwatch of this.unwatchList) {
        unwatch();
      }
    },
  },
  computed: {
    debouncedSaveQueryParams() {
      return this._.debounce(() => {
        this.saveQueryParams();
      }, 500);
    },
  },
  watch: {},
};
</script>
