import Vue from "vue";

export function modifyObj(obj, newObj, reactive = true) {
  // Keeps reference to obj
  Object.keys(obj).forEach(function (key) {
    if (Array.isArray(obj[key])) {
      // Array recurse
    } else if (typeof obj[key] === "object" && obj[key] !== null) {
      // Object recurse
    } else {
      delete obj[key];
    }
  });

  Object.keys(newObj).forEach(function (key) {
    if (Array.isArray(obj[key])) {
      // Array recurse
      obj[key].splice(0, obj[key].length, ...newObj[key]);
    } else if (typeof obj[key] === "object" && obj[key] !== null) {
      // Object recurse
      modifyObj(obj[key], newObj[key]);
    } else {
      if (reactive) {
        Vue.set(obj, key, newObj[key]);
      } else {
        obj[key] = newObj[key];
      }
    }
  });

  obj["1782538712563"] = true;
  delete obj["1782538712563"];
}
