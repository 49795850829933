import Vue from "vue";
import Vuex from "vuex";
import { cloneDeep } from "lodash";
import { ordergrid } from "./ordergrid";
import { querysave } from "./querysave";
import { hyperlinks } from "./hyperlinks";
import { rowsaver } from "./rowsaver";
import { priceMismatchModal } from "./priceMismatchModal";
import { disappearingHelper } from "./disappearingHelper";

Vue.use(Vuex);

function globalAddAlert(state, alert) {
  if (state.alerts[state.alerts.length - 1]?.text == alert.text) {
    console.log("Prevented duplicate alert.");
    return;
  }
  state.alerts.unshift(alert);
  if (alert.timeout !== null) {
    let timeout = alert.timeout;
    if (timeout === undefined) {
      timeout = 5000;
    }
    setTimeout(() => {
      state.alerts.splice(0, 1);
    }, timeout);
  }
}

var store = new Vuex.Store({
  modules: {
    ordergrid: ordergrid,
    querysave: querysave,
    hyperlinks: hyperlinks,
    rowsaver: rowsaver,
    priceMismatchModal: priceMismatchModal,
    disappearingHelper: disappearingHelper,
  },
  state: {
    // Related to adding new products to an order
    alerts: [],
    jobs: [],

    infoText: "", // Shows in toolbar

    undoRedoStack: [],
    undoRedoIndex: -1,
    undoRedoLock: false,

    modifierKeys: {
      Control: false,
      Shift: false,
      Meta: false,
      Mouse: false,
    },
  },
  mutations: {
    setInfoText(state, text) {
      state.infoText = text;
    },
    addAlert(state, alert) {
      globalAddAlert(state, alert);
    },
    async addJob(state, { callback, undo }) {
      if (state.undoRedoIndex != state.undoRedoStack.length - 1) {
        console.debug("Clearing redos from undoStack");
        state.undoRedoStack.splice(state.undoRedoIndex + 1);
      }
      if (undo != undefined) {
        state.undoRedoStack.push({
          redo: callback,
          undo: undo,
        });
        state.undoRedoIndex = state.undoRedoStack.length - 1;

        console.log("Initiated undoable action. state: ", state);
        if (state.undoRedoStack.length > 100) {
          state.undoRedoStack.shift();
          state.undoRedoIndex--;
        }
      }
      if (state.jobs.length > 0) {
        await state.jobs[0];
      }
      let promise = callback(state);
      promise = promise
        .then(() => {
          console.debug(`Job ${promise} is finished`);
          state.jobs = state.jobs.filter((job) => job !== promise);
        })
        .catch((err) => {
          console.error(err);
          state.jobs = state.jobs.filter((job) => job !== promise);
          let message = err;
          if (err.response) {
            message = JSON.stringify(err.response.data);
          }
          globalAddAlert(state, {
            type: "error",
            text: "Virhe! " + message,
          });
          if (undo != undefined) {
            state.undoRedoStack[state.undoRedoStack.length - 1].undo();
            state.undoRedoStack.pop();
            state.undoRedoIndex = state.undoRedoStack.length - 1;
            console.log("Failed undoable action (undone). state: ", state);
          }
        });
      state.jobs.push(promise);
    },
    undoJob(state) {
      if (state.undoRedoIndex == -1) {
        return;
      }
      if (state.undoRedoLock) {
        return;
      }
      let currentItem = state.undoRedoStack[state.undoRedoIndex];
      // Run undo callback
      state.undoRedoLock = true;
      currentItem.undo().then(() => {
        state.undoRedoIndex -= 1;
        state.undoRedoLock = false;
        console.debug("Undone. ", state);
      });
    },
    redoJob(state) {
      if (state.undoRedoIndex == state.undoRedoStack.length - 1) {
        return;
      }
      if (state.undoRedoLock) {
        return;
      }
      state.undoRedoIndex += 1;
      let nextItem = state.undoRedoStack[state.undoRedoIndex];
      // Run redo callback
      state.undoRedoLock = true;
      nextItem.redo(state).then(() => {
        state.undoRedoLock = false;
        console.debug("Redone. ", state);
      });
      // No need to revert state, redo should take care of it
    },
    clearUndoQueue() {
      // Just run the subscribe function explicitly
    },
    modifierDown(state, key) {
      state.modifierKeys[key] = true;
    },
    modifierUp(state, key) {
      state.modifierKeys[key] = false;
    },
  },
  getters: {
    allowRedo(state) {
      return state.undoRedoIndex < state.undoRedoStack.length - 1;
    },
    allowUndo(state) {
      return state.undoRedoIndex > -1;
    },
    status(state) {
      // status based on current jobs
      for (const job of state.jobs) {
        console.log(job);
        if (job.status === "rejected") {
          return "error";
        } else {
          return "loading";
        }
      }
      return "success";
    },
  },
  actions: {},
});

store.subscribe((mutation, state) => {
  // is called AFTER every mutation
  if (mutation.type.includes("/")) {
    return;
  }
  if (
    [
      "undoJob",
      "redoJob",
      "addJob",
      "modifierDown",
      "modifierUp",
      "addAlert",
    ].includes(mutation.type)
  ) {
    if (mutation.type == "addJob") {
      if (mutation.payload.undo == undefined) {
        return;
      } else {
        console.debug("addJob with undo");
      }
    }
    return;
  }
  console.debug("Clearing undo queue");
  state.undoRedoStack = [];
  state.undoRedoIndex = -1;
});

export default store;
