var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{ref:"me",style:({
    'writing-mode': _vm.transformed ? 'vertical-lr' : 'horizontal-tb',
    transform: _vm.transformed
      ? _vm.partially
        ? 'rotate(225deg)'
        : 'rotate(190deg)'
      : 'none',
    'padding-top': _vm.transformed ? '0.5rem' : '0',
    'padding-bottom': _vm.transformed ? '0.5rem' : '0',
    'font-size': _vm.smallerFont ? '0.8rem' : _vm.fontSize,
  })},[_vm._v(" "+_vm._s(_vm.text)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }