import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/dayjs";
import axios from "axios";
import VueAxios from "vue-axios";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import Cookies from "js-cookie";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { ObserveVisibility } from "vue-observe-visibility";

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueLodash, { lodash: lodash });

Vue.directive("observe-visibility", ObserveVisibility);

Vue.prototype.$log = console.debug;

/**
 * Request interceptor: for each request to the server,
 * attach the CSRF token if it exists.
 *
 */
axios.interceptors.request.use(async (request) => {
  try {
    const csrf = Cookies.get("csrftoken");
    request.withCredentials = true;

    if (csrf) {
      request.headers.common["X-CSRFToken"] = csrf;
    }

    return request;
  } catch (err) {
    throw new Error(
      `axios# Problem with request during pre-flight phase: ${err}.`
    );
  }
});

// Set axios default error handler to show a toast
axios.interceptors.response.use(
  (response) => response,
  (err) => {
    let text = "";
    if (err.response?.status == 500) {
      text = "Palvelinvirhe!";
    } else if (err.response?.data) {
      text = JSON.stringify(err.response.data);
    } else {
      text = err;
    }
    store.commit("addAlert", {
      type: "error",
      text: text,
    });
    throw err;
  }
);

Vue.mixin({
  methods: {
    errorHandler: function (err) {
      let text = "";
      console.error(err);
      if (err.response?.status == 500) {
        text = "Palvelinvirhe!";
      } else if (err.response?.data) {
        console.error(err.response.data);
        text = JSON.stringify(err.response.data);
      } else {
        text = err;
      }
      store.commit("addAlert", {
        type: "error",
        text: text,
      });
    },
  },
});

if (process.env == "production") {
  Sentry.init({
    Vue,
    dsn: "https://f3d0cd69218e4e0f9b4810bdc1beecf7@sentry.dev.sos.fi/13",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["metso.dev.sos.fi", "metso.sos.fi", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
