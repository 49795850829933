<template>
  <v-app>
    <v-app-bar dense app color="primary" dark>
      <v-toolbar-title>Metso</v-toolbar-title>
      <router-link to="/products">Products</router-link>
      <div v-if="$store.state.infoText">
        <h3>{{ $store.state.infoText }}</h3>
      </div>
      <v-spacer></v-spacer>

      <div>
        <v-text-field
          type="number"
          v-model="maxVisibleComponents"
          label="Komponenttien maksimimäärä sivulla"
          style="margin-bottom: -50px"
        >
        </v-text-field>
      </div>

      <div v-if="$store.state.ordergrid.shiftSelectSum">
        {{ $store.state.ordergrid.shiftSelectSum }}
      </div>

      <v-btn icon :disabled="!allowUndo" @click="undoJob()">
        <v-icon>mdi-undo</v-icon>
      </v-btn>

      <v-btn icon :disabled="!allowRedo" @click="redoJob()">
        <v-icon>mdi-redo</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon v-if="status == 'success'">mdi-cloud-check</v-icon>
        <v-progress-circular
          indeterminate
          v-else-if="status == 'loading'"
        ></v-progress-circular>
        <v-icon v-else>mdi-alert</v-icon>
      </v-btn>
      <template v-slot:extension>
        <v-tabs align-with-title>
          <v-tab :to="`/orders/${orderId || ''}`">Tilaukset</v-tab>
          <v-tab :to="`/upload/`">Tuo dataa</v-tab>
          <v-tab :to="`/weekly-orders/`">Saapuvat toimitukset</v-tab>
          <v-tab v-if="orderId" :to="`/order/${orderId}/edit/`">Muokkaa</v-tab>
          <v-tab v-if="orderId" :to="`/order/${orderId}/history/`"
            >Historia</v-tab
          >
          <v-tab v-if="fileId" :to="`/upload/${fileId}/parse`"
            >Parsi tiedosto</v-tab
          >
          <v-tab v-if="orderId" :to="`/order/${orderId}/add_products/`"
            >Lisää tuotteita</v-tab
          >
          <v-tab v-if="orderId" :to="`/order/${orderId}/manage/`"
            >Määrävalinta</v-tab
          >
          <v-tab v-if="orderId" :to="`/order/${orderId}/pivot/`"
            >Ruudukko</v-tab
          >
          <v-tab v-if="orderId" :to="`/order/${orderId}/export/`"
            >Vie tilaus</v-tab
          >
          <v-tab v-if="orderId" :to="`/order/${orderId}/confirm/`"
            >Vahvista tilaus</v-tab
          >
        </v-tabs>
      </template>
    </v-app-bar>

    <v-main>
      <div v-if="alerts" class="app-alert-wrapper">
        <v-alert
          class="app-alert"
          v-for="(alert, index) in alerts"
          :key="`app-alert-${index}`"
          dense
          dismissible
          :type="alert.type"
          ><div v-for="(row, index) in alert.text.split('\n')" :key="index">
            {{ row }}
          </div>
        </v-alert>
      </div>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  metaInfo: {
    title: "Metso",
  },

  components: {},
  data: () => ({
    drawer: false,
    group: null,
  }),

  mounted() {
    window.addEventListener("keydown", (event) => {
      if (event.key == "Control" || event.key == "Meta") {
        this.$store.commit("modifierDown", "Control");
      } else if (event.key == "Shift") {
        this.$store.commit("modifierDown", "Shift");
      }
    });
    window.addEventListener("keyup", (event) => {
      if (event.key == "Control" || event.key == "Meta") {
        this.$store.commit("modifierUp", "Control");
      } else if (event.key == "Shift") {
        this.$store.commit("modifierUp", "Shift");
      }
    });
    window.addEventListener("mousedown", (event) => {
      this.$store.commit("modifierDown", "Mouse");
    });
    window.addEventListener("mouseup", (event) => {
      this.$store.commit("modifierUp", "Mouse");
    });
  },

  computed: {
    maxVisibleComponents: {
      get() {
        return this.$store.state.disappearingHelper.maxVisibleComponents;
      },
      set(value) {
        this.$store.commit("disappearingHelper/setMaxVisibleComponents", value);
      },
    },
    status() {
      return this.$store.getters.status;
    },
    orderId() {
      return this.$route.params.orderId;
    },
    fileId() {
      return this.$route.params.fileId;
    },
    showExtension() {
      return !!this.orderId;
    },
    alerts() {
      return this.$store.state.alerts;
    },
    allowUndo() {
      if (this.status == "loading") {
        return false;
      }
      return this.$store.getters.allowUndo;
    },
    allowRedo() {
      if (this.status == "loading") {
        return false;
      }
      return this.$store.getters.allowRedo;
    },
  },

  methods: {
    undoJob() {
      this.$store.commit("undoJob");
    },
    redoJob() {
      this.$store.commit("redoJob");
    },
  },

  watch: {
    group() {
      this.drawer = false;
    },
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || "Metso";
      },
    },
  },
};
</script>

<style scoped lang="scss">
.app-alert-wrapper {
  position: fixed;
  right: 0;
  top: 5rem;
  z-index: 999;
  min-width: 25rem;
}
.v-main {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
</style>
