<template>
  <tbody v-if="thisIsVisible" ref="main" v-observe-visibility="{
      callback: visibilityChanged,
      intersection: {
        rootMargin: '1000px',
        threshold: 0,
      },
    }">
    <slot></slot>
  </tbody>
  <tbody
    v-else
    ref="main"
    :style="myStyle"
    v-observe-visibility="{
      callback: visibilityChanged,
      intersection: {
        rootMargin: '1000px',
        threshold: 0,
      },
    }"
  >
    <tr :style="myStyle">
      <td :style="myStyle" :colspan="colSpan"><slot name="empty">...</slot></td>
    </tr>
  </tbody>
</template>

<script>
export default {
  name: "DisappearingTBody",

  props: ["visible", "initialHeight", "colSpan"],

  data: () => ({
    height: "10px",
  }),

  methods: {
    visibilityChanged(isVisible) {
      this.$store.commit("disappearingHelper/setComponentVisible", {componentId: this.componentId, visible: isVisible});
    },
  },

  watch: {
    initialHeight: {
      handler(newValue) {
        console.log("initialHeight", newValue);
        if (newValue) {
          this.height = newValue;
        }
      },
      immediate: true,
    },
    thisIsVisible(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.height = `${this.$refs.main.offsetHeight}px`;
        });
      }
    },
  },

  computed: {
    componentId() {
      // Unique id for this component.
      const id = this._uid;
      return `disappearing-tbody-${id}`;
    },
    thisIsVisible() {
      return this.$store.getters["disappearingHelper/componentVisible"](this.componentId);
    },
    myStyle() {
      return { height: this.height };
    },
  },
};
</script>

<style scoped></style>
