<template>
  <div style="display: flex; flex-wrap: nowrap; align-items: center">
    <slot
      :newDateGroup="newDateGroup"
      :updateFunc="debouncedSaveDate"
      :clearFunc="clearDateGroup"
    ></slot>
  </div>
</template>

<script>
// Wrapper component to edit dateGroup values ("dateGroup" for a single store+date combination).
// Use like this:
// <template v-slot="{ newDateGroup, updateFunc }">
//   <input v-model="newDateGroup.date" />
// </template>
//
// DateGroupEdit will handle debounce and api call to persist data

import { modifyObj } from "../utils/utils";
import Vue from "vue";

export default {
  name: "DateGroupEdit",

  props: {
    dateGroup: {
      type: Object,
    },
    storeGroup: {
      type: Object,
    },
    order: {
      type: Object,
    },
    debounce: {
      type: Number,
      default: 1000,
    },
  },

  data: () => ({
    newDateGroup: {},
  }),

  mounted() {
    this.loadOlddateGroup(this.olddateGroup);
  },

  computed: {
    debouncedSaveDate() {
      return this._.debounce(this.saveDate, this.debounce);
    },
    olddateGroup() {
      return this.dateGroup;
    },
  },

  methods: {
    loadOlddateGroup(newOlddDateGroup) {
      this.newDateGroup = this._.cloneDeep(newOlddDateGroup);
      if (this.newDateGroup.isNew) {
        console.debug("dateGroup isNew! Persisting...");
        this.saveDate({ force: true });
      }
    },
    clearDateGroup() {
      Object.values(this.olddateGroup.groups).forEach((row) => {
        row.quantity = "DELETE";
      });
      Object.values(this.newDateGroup.groups).forEach((row) => {
        row.quantity = "DELETE";
      });
      this.saveDate({ force: true });
    },
    saveDate(options) {
      options = options || {};
      const force = !!options.force;
      if (!force && this.newDateGroup.date == this.olddateGroup.date) {
        return;
      }

      Object.values(this.newDateGroup.groups).forEach((row) => {
        row.delivery_date = this.newDateGroup.date;
      });
      const newDateGroup = this._.cloneDeep(this.newDateGroup);

      const preventUndo = newDateGroup.isNew;
      newDateGroup.isNew = false;
      const olddateGroup = this._.cloneDeep(this.olddateGroup);

      const newDateGroupRef = this.newDateGroup;
      const olddateGroupRef = this.olddateGroup;

      const storeGroup = this.storeGroup;
      const orderId = this.order.id;

      this.$store.commit("addJob", {
        callback: () => {
          console.debug("saveDate: ", newDateGroup);
          // Move dateGroup to new key in parent
          delete storeGroup.groups[olddateGroup.date];
          Vue.set(storeGroup.groups, newDateGroup.date, olddateGroupRef);

          modifyObj(newDateGroupRef, newDateGroup);
          modifyObj(olddateGroupRef, newDateGroup);

          return this.axios
            .post(`/api/orders/order/${orderId}/update_rows/`, {
              rows: Object.values(newDateGroup.groups),
            })
            .then((resp) => {
              console.log(resp);
              if ((resp.data.deleted || []).length > 0) {
                this.$store.commit("addAlert", {
                  type: "success",
                  text: `Rivit poistettu onnistuneesti.`,
                });
                for (const row of resp.data.deleted) {
                  row.quantity = "DELETE";
                  modifyObj(newDateGroupRef.groups[row.size], row);
                  modifyObj(olddateGroupRef.groups[row.size], row);
                }
              } else {
                for (const row of resp.data.updated) {
                  modifyObj(newDateGroupRef.groups[row.size], row);
                  modifyObj(olddateGroupRef.groups[row.size], row);
                }
              }
            });
        },
        undo: preventUndo
          ? undefined
          : () => {
              console.debug("Undoing saveDate: ", olddateGroup);
              // Move dateGroup to old key in parent
              delete storeGroup.groups[newDateGroup.date];
              Vue.set(storeGroup.groups, olddateGroup.date, olddateGroupRef);

              modifyObj(olddateGroupRef, olddateGroup);
              modifyObj(newDateGroupRef, olddateGroup);
              console.debug("olddateGroupRef:", olddateGroupRef);
              console.debug("newDateGroupRef:", newDateGroupRef);

              return this.axios
                .post(`/api/orders/order/${orderId}/update_rows/`, {
                  rows: Object.values(olddateGroup.groups),
                })
                .then((resp) => {
                  console.log(resp);
                  // Pick up new ID's of created rows
                  for (const row of resp.data.updated) {
                    modifyObj(newDateGroupRef.groups[row.size], row);
                    modifyObj(olddateGroupRef.groups[row.size], row);
                  }
                });
            },
      });
    },
  },

  watch: {
    dateGroup: {
      handler(newOlddDateGroup) {
        this.loadOlddateGroup(newOlddDateGroup);
      },
      deep: true,
    },
    storeGroup: {
      handler() {
        console.log("DateGroupEdit storeGroup watcher handler");
        this.loadOlddateGroup(this.olddateGroup);
      },
      deep: true,
    },
    "newDateGroup.date"(newDate) {
      if (newDate != this.olddateGroup.date) {
        this.debouncedSaveDate();
      }
    },
  },
};
</script>
