<template>
  <v-data-table
    :items="orders"
    :headers="columns"
    :items-per-page="10000"
    :expanded.sync="expanded"
    show-expand
    single-expand
    hide-default-footer
  >
    <template v-slot:[`item.supplier_id`]="{ item }">
      {{
        (
          ((choices.suppliers || []).filter(
            (row) => row.id == item.supplier_id
          ) || [null])[0] || {}
        ).name
      }}
    </template>
    <template v-slot:[`item.store_id`]="{ item }">
      {{
        (
          ((choices.stores || []).filter((row) => row.id == item.store_id) || [
            null,
          ])[0] || {}
        ).name
      }}
    </template>
    <template v-slot:[`header.ordernumber`]="{}">
      <span>Tilausnumero</span>
      <v-btn style="display: block" @click="setAllOrdernumbers" :disabled="!fixingStatus">
        <v-icon>mdi-pencil</v-icon>
        Aseta kaikkiin
      </v-btn>
    </template>
    <template v-slot:[`item.ordernumber`]="{ item }">
      <v-text-field
        :value="item.ordernumber"
        :disabled="!item.ordernumber_editable || !fixingStatus"
        @change="updateOrderNumber(item, $event)"
      ></v-text-field>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td v-if="rowsByEAN" :colspan="headers.length">
        <v-btn @click="rowsByEAN = false">Näytä enareittain</v-btn>
        <v-data-table
          v-for="simpleTable in item.simple_sizegroup_tables"
          :key="simpleTable.sizegroup"
          :items="simpleTable.rows"
          :headers="simpleTableColumns(simpleTable.size_columns)"
          :items-per-page="10000"
          hide-default-footer
        >
        </v-data-table>
      </td>
      <td v-if="!rowsByEAN" :colspan="headers.length">
        <v-btn @click="rowsByEAN = true">Näytä väreittäin</v-btn>
        <v-data-table
          :items="item.rows"
          :headers="rowColumns"
          :items-per-page="10000"
          hide-default-footer
        >
          <template v-slot:[`item.size`]="{ item }">
            {{
              (
                ((choices.sizes || []).filter((row) => row.id == item.size) || [
                  null,
                ])[0] || {}
              ).name
            }}
          </template>
        </v-data-table>
      </td>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "ChildOrderTable",
  components: {},

  props: {
    choices: {
      type: Object,
      default: null,
    },
    store: {
      type: Number,
      default: null,
    },
    orders: {
      type: Array,
      default: null,
    },
    updateOrderNumber: {
      type: Function,
      default: null,
    },
    order: {
      type: Object,
      default: null,
    },
  },

  data: function () {
    return {
      expanded: [],
      rowsByEAN: false,
      rowColumns: [
        {
          text: "EAN",
          value: "ean",
        },
        {
          text: "Tuotemalli",
          value: "name",
        },
        {
          text: "Väri",
          value: "color__name",
        },
        {
          text: "Koko",
          value: "size",
        },
        {
          text: "kpl",
          value: "quantity",
        },
      ],
    };
  },

  computed: {
    fixingStatus() {
      if (!this.order) {
        return true;
      }
      return this.order.status == "fixing";
    },
    columns() {
      const ret = [
        {
          text: "Viikko",
          value: "delivery_date",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Metso-tunnus",
          value: "id",
        },
        {
          text: "Tilausnumero",
          value: "ordernumber",
        },
        {
          text: "Nimi",
          value: "name",
        },
        {
          text: "Toimittaja",
          value: "supplier_id",
        },
        {
          text: "Tuotemalleja",
          value: "total_color_groups",
        },
        {
          text: "Tuotteita kpl",
          value: "total_quantity",
        },
      ];
      if (!this.store) {
        ret.push({
          text: "Myymälä",
          value: "store_id",
        });
      }
      return ret;
    },
  },

  methods: {
    async setAllOrdernumbers() {
      const ordernumber = prompt("Anna tilausnumero");
      for (const order of this.orders) {
        if (order.ordernumber_editable) {
          await this.updateOrderNumber(order, ordernumber).catch((err) => {
            this.$store.commit("addAlert", {
              type: "error",
              text: `Virhe: ${err}`,
            });
          });
        }
      }
    },
    simpleTableColumns(sizeColumns) {
      const ret = [
        {
          text: "Tuotemalli",
          value: "name",
        },
        {
          text: "Väri",
          value: "color__name",
        },
      ];
      for (const size of sizeColumns) {
        ret.push({
          text: size.text,
          value: size.value,
        });
      }
      return ret;
    },
  },
};
</script>
