<template>
  <v-container fluid class="product-list">
    <v-row v-if="!order">
      <v-progress-linear
          :indeterminate="true"
        ></v-progress-linear>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-select
          v-model="selectedFilter.supplier_id"
          :items="
            (choices.suppliers || []).map((row) => ({
              value: row.id,
              text: row.name,
            }))
          "
          :label="'Toimittaja'"
          dense
          hide-details="auto"
        >
        </v-select>
      </v-col>
      <v-col cols="4" v-if="order">
        <h3>Valittu hintakatalogi: {{ order.selected_price_catalog || 'Oletus' }}</h3>
        <h3>Alennusprosentti: {{ order.discount_percentage || '-' }}</h3>
      </v-col>
    </v-row>
    <v-row><v-col cols="12">
    <div class="d-flex">
      <v-data-table
        class="flex-fill"
        :headers="columns"
        :items="rows"
        paginated
        :items-per-page="perPage"
        :server-items-length="totalModels"
        :loading="loading"
        v-model="selectedRows"
        @update:options="updateOptions"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            v-if="alreadyInOrder(item)"
            @click="removeFromOrder(item.color_group_str)"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
          <v-btn v-else icon @click="addToOrder(item)"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
        </template>

        <template v-slot:[`item.size__name`]="{ item }">
          {{ item.products.map((product) => product.size__name) }}
        </template>

        <template v-slot:[`item.purchase_price`]="{ item }">
          <span v-if="!item.purchase_price || item.price_catalog" class="good-price-catalog">{{ item.purchase_price || '...' }}</span>
          <span v-else class="no-price-catalog" title="Ei hintoja tuotteelle valitussa katalogissa">
            <v-icon>mdi-alert-circle</v-icon>
            {{ item.purchase_price || '...' }}
          </span>
        </template>


        <template
          v-for="col in availableFields.map((field) => field.value)"
          v-slot:[`header.${col}`]="{ header }"
        >
          <div class="table-column-header" :key="col">
            <span v-if="header.value == 'actions'"></span>
            <v-autocomplete
              v-else-if="filterChoices[header.value] != undefined"
              :items="filterChoices[header.value]"
              v-model="selectedFilter[`${header.full_value}`]"
              dense
              hide-details="auto"
            >
            </v-autocomplete>
            <v-text-field
              v-else
              dense
              hide-details="auto"
              v-model="selectedFilter[`${header.full_value}`]"
            ></v-text-field>
            <div
              v-if="header._sortable"
              class="header-text header-sortable"
              @click="setSortBy(header.value)"
            >
              {{ header.text
              }}<span v-if="sortBy[0] == header.value">
                <v-icon v-if="sortDesc[0]">mdi-arrow-down</v-icon>
                <v-icon v-else>mdi-arrow-up</v-icon>
              </span>
              <span v-else-if="sortBy[1] == header.value">
                <v-icon v-if="sortDesc[1]">mdi-arrow-down</v-icon>
                <v-icon v-else>mdi-arrow-up</v-icon>
                2
              </span>
            </div>
            <span class="header-text" v-else>{{ header.text }}</span>
          </div>
        </template>
      </v-data-table>
      <v-card style="width: 500px">
        <v-card-title> Tilauksessa </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item v-for="(orderProduct, i) in orderProducts" :key="i"
              ><v-btn
                icon
                @click="removeFromOrder(orderProduct.color_group_str)"
                ><v-icon>mdi-delete</v-icon></v-btn
              ><v-btn
                v-if="i != 0"
                icon
                @click="moveSortOrderUp(orderProduct.color_group_str)"
                ><v-icon>mdi-arrow-up</v-icon></v-btn
              >
              {{ orderProduct.totalQuantity }} / {{ orderProduct.name }}
              {{ orderProduct.color__name }}
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </div>
    </v-col></v-row>
  </v-container>
</template>

<script>
import QuerySave from "../mixins/QuerySave.vue";

export default {
  name: "ProductList",
  mixins: [QuerySave],
  data: () => ({
    choices: {},
    querySave: ["selectedFilter", "sortBy", "sortDesc"],
    availableFields: [
      { value: "actions", text: "" },
      {
        value: "modelnumber",
        text: "Mallinumero",
        full_value: "modelnumber__icontains",
      },
      { value: "name", text: "Nimi", full_value: "name__icontains" },
      {
        value: "color__name",
        text: "Väri",
        full_value: "color_id",
      },
      {
        value: "raw_color",
        text: "Värikoodi",
        full_value: "raw_color__icontains",
      },
      {
        value: "main_group__name",
        text: "Tuoteryhmä",
        full_value: "main_group_id",
      },
      {
        value: "raw_season",
        text: "Sesonki",
        full_value: "raw_season",
      },
      {
        value: "raw_property_division",
        text: "Division",
        full_value: "raw_property_division",
      },
      {
        value: "raw_property_concept",
        text: "Concept",
        full_value: "raw_property_concept",
      },
      {
        value: "raw_property_family",
        text: "Family",
        full_value: "raw_property_family",
      },
      {
        value: "type__name",
        text: "Tyyppi",
        full_value: "type_id",
      },
      {
        value: "purchase_price",
        text: "NETTO",
        full_value: "purchase_price",
      },
      {
        value: "net_price",
        text: "ENETTO",
        full_value: "net_price",
      },
    ],

    rows: [],

    page: 1,
    perPage: 500,
    totalModels: 0,
    sortBy: [],
    sortDesc: [],
    ready: false,
    loading: false,
    selectedFilter: {},

    selectedRows: [],

    order: null,
  }),
  mounted() {
    this.fetchOrder();
    this.$nextTick(() => {
      this.ready = true;
    });
  },
  computed: {
    orderProducts() {
      const ret = [];
      if (!this.order || !this.order.groups) {
        return ret;
      }
      for (const colorGroup of Object.values(this.order.groups)) {
        try {
          const row = Object.values(
            Object.values(Object.values(colorGroup.groups)[0].groups)[0].groups
          )[0];
          // Egh, this looks scary. But we're just summing three levels of row quantities.
          row.totalQuantity = Object.values(colorGroup.groups).reduce(
            (acc, cur) =>
              acc +
              Object.values(cur.groups).reduce(
                (acc, cur) =>
                  acc +
                  Object.values(cur.groups).reduce(
                    (acc, cur) => acc + (cur.quantity || 0),
                    0
                  ),
                0
              ),
            0
          );
          ret.push(row);
        } catch (err) {
          console.log(err);
        }
      }
      return ret;
    },
    debouncedFetchRows() {
      return this._.debounce(this.fetchRows, 500);
    },
    filterChoices() {
      return {
        color__name: this.mapChoices(this.choices["colors"]),
        manufacturer__name: this.mapChoices(this.choices["manufacturers"]),
        main_group__name: this.mapChoices(this.choices["productgroups"]),
        raw_season: this.mapChoices(this.choices["seasons"]),
        raw_property_division: this.mapChoices(this.choices["divisions"]),
        raw_property_concept: this.mapChoices(this.choices["concepts"]),
        raw_property_family: this.mapChoices(this.choices["familys"]),
        type__name: this.mapChoices(this.choices["types"]),
      };
    },
    columns() {
      return this.availableFields.map((selectedField) => {
        return {
          value: selectedField.value,
          text: selectedField.text,
          sortable: false,
          filterable: false,
          _sortable: true,
          full_value: selectedField.full_value || selectedField.value,
        };
      });
    },
  },
  methods: {
    mapChoices(choiceList) {
      if (!choiceList) {
        return [];
      }
      console.log("choiceList", choiceList);
      return [{ value: "", text: "" }].concat(
        choiceList.map((c) => ({
          value: c.id,
          text: c.name,
        }))
      );
    },
    setSortBy(col) {
      this.sortBy[0] = col;
      this.sortDesc[0] = !this.sortDesc[0];
      console.log(this.sortBy, this.sortDesc);
      this.fetchRows();
    },
    fetchOrder(options = { fetchChoices: true, fetchProducts: true}) {
      const {fetchChoices, fetchProducts} = options;
      this.axios
        .get(
          "/api/orders/order/" +
            this.$route.params.orderId +
            "/?grouping=color_group,store,date"
        )
        .then((resp) => {
          this.order = resp.data;
          this.selectedFilter.supplier_id = this.order.supplier;
          console.log("Order fetched");
          if (fetchProducts) {
            this.fetchRows({ fetchChoices });
          }
        });
    },
    fetchRows(options = { fetchChoices: true }) {
      const fetchChoices = options.fetchChoices;
      this.loading = true;
      const url = this.generateUrl() + `&order_id=${this.$route.params.orderId}`;
      if (fetchChoices) {
        this.fetchProductChoices();
      }
      this.axios.get(url).then((resp) => {
        console.log(resp);
        this.rows = resp.data.results;
        this.totalModels = resp.data.count;
        this.loading = false;
      });
    },
    fetchProductChoices() {
      //Get all possible suppliers, colors, etc.
      let url = `&${this.generateUrl().split("?")[1]}`;
      this.axios
        .get(`/api/products/choices/?order=${this.$route.params.orderId}${url}`)
        .then((resp) => {
          console.log(resp);
          this.choices = { ...this.choices, ...resp.data };
        });
    },
    generateUrl() {
      const baseUrl = "/api/products/products/";
      let url = "?page=" + this.page + "&page_size=" + this.perPage;
      if (this.sortBy[0]) {
        url += "&order_by=";
        if (this.sortDesc[0]) {
          url += "-";
        }
        url += this.sortBy[0];
      }
      if (this.sortBy[1]) {
        url += "&order_by2=";
        if (this.sortDesc[1]) {
          url += "-";
        }
        url += this.sortBy[1];
      }
      if (this.selectedFilter) {
        for (let key in this.selectedFilter) {
          if (
            this.selectedFilter[key] != undefined &&
            this.selectedFilter[key] != ""
          ) {
            url +=
              "&" + key + "=" + encodeURIComponent(this.selectedFilter[key]);
          }
        }
      }
      //this.saveUrlParams(url) TODO: Save url params for later?
      return baseUrl + url;
    },
    updateOptions(event) {
      this.page = event.page;
      this.sortBy = event.sortBy;
      this.sortDesc = event.sortDesc;
      if (!this.ready) {
        return;
      }
      this.fetchRows();
    },
    alreadyInOrder(colorGroup) {
      if (!this.order) {return false;}
      return (
        colorGroup.color_group_str in this.order.selectedproducts_by_color_group
      );
    },
    removeFromOrder(color_group_str) {
      const selected_products = Object.values(
        this.order.selectedproducts_by_color_group[color_group_str]
      );
      this.axios
        .post("/api/orders/remove_products/", {
          selected_products,
        })
        .then((resp) => {
          this.fetchOrder({fetchProducts: false});
          console.log(resp.data);
        });
    },
    moveSortOrderUp(color_group_str) {
      const selected_products = Object.values(
        this.order.selectedproducts_by_color_group[color_group_str]
      );
      this.axios
        .post("/api/orders/update_products/", {
          selected_products,
          action: "sort_order_up",
        })
        .then((resp) => {
          this.fetchOrder();
          console.log(resp.data);
        });
    },
    addToOrder(item) {
      console.log(item);
      this.axios
        .post("/api/orders/add_products/", {
          color_groups: [item],
          order_id: this.$route.params.orderId,
        })
        .then((resp) => {
          this.fetchOrder({fetchProducts: false});
          console.log(resp.data);
        });
    },
  },
  watch: {
    selectedFilter: {
      handler() {
        if (!this.order) {return;}
        console.log("selectedFilter changed");
        this.page = 1;
        this.debouncedFetchRows();
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.no-price-catalog {
  color: red;
  font-weight: bold;
  display: flex;
  flex-wrap: nowrap;
}
.good-price-catalog {
  color: green;
  font-weight: bold;
  display: flex;
  flex-wrap: nowrap;
}
</style>
