import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ProductList from "../views/ProductList.vue";
import ManageOrder from "../views/ManageOrder.vue";
import EditOrder from "../views/EditOrder.vue";
import OrderList from "../views/OrderList.vue";
import PivotOrder from "../views/PivotOrder.vue";
import ExportOrder from "../views/ExportOrder.vue";
import OrderHistory from "../views/OrderHistory.vue";
import WeeklyOrders from "../views/WeeklyOrders.vue";
import ConfirmOrder from "../views/ConfirmOrder.vue";

import UploadFiles from "../views/UploadFiles.vue";
import ParseFile from "../views/ParseFile.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: OrderList,
    meta: {
      title: "Metso",
    },
  },
  {
    path: "/orders/",
    name: "Orders",
    component: OrderList,
  },
  {
    path: "/weekly-orders/",
    name: "WeeklyOrders",
    component: WeeklyOrders,
  },
  {
    path: "/orders/:orderId/",
    name: "Orders",
    component: OrderList,
  },
  {
    path: "/order/:orderId/add_products",
    name: "Products",
    component: ProductList,
  },
  {
    path: "/order/:orderId/edit",
    name: "EditOrder",
    component: EditOrder,
  },
  {
    path: "/order/:orderId/manage",
    name: "ManageOrder",
    component: ManageOrder,
  },
  {
    path: "/order/:orderId/pivot",
    name: "PivotOrder",
    component: PivotOrder,
  },
  {
    path: "/order/:orderId/export",
    name: "ExportOrder",
    component: ExportOrder,
  },
  {
    path: "/order/:orderId/confirm",
    name: "ConfirmOrder",
    component: ConfirmOrder,
  },
  {
    path: "/order/:orderId/history",
    name: "OrderHistory",
    component: OrderHistory,
  },
  {
    path: "/upload/",
    name: "Upload",
    component: UploadFiles,
  },
  {
    path: "/upload/:fileId/parse",
    name: "Parse",
    component: ParseFile,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
