<template>
  <div class="manage-order">
    <PriceMismatchModal></PriceMismatchModal>
    <ShiftPainter :topOffset="-96" :leftOffset="-8"></ShiftPainter>
    <div
      ref="sticky-header"
      class="sticky-header"
      :style="{ top: `96px` }"
    ></div>
    <v-row class="mt-0">
      <v-toolbar dense>
        <v-toolbar-items>
          <v-btn
            v-for="choice in storeFilterChoices"
            :key="choice.value"
            :disabled="storeFilter === choice.value"
            @click="
              loadingWrapper(true).then(() => {
                storeFilter = choice.value;
              })
            "
            >{{ choice.text }}</v-btn
          >
          <div class="mr-4"></div>

          <copy-to-store-modal
            ref="copy-to-store-modal"
            v-if="storeFilter"
            :order="order"
            :colorGroups="colorGroups"
            :storeFilter="storeFilter"
            :storeChoices="storeFilterChoices"
            :showButton="false"
            @copyComplete="
              loadingWrapper(true).then(() => {
                loadReactiveRows();
                storeFilter = $event.selectedStore;
              })
            "
          ></copy-to-store-modal>
          <v-btn
            v-if="storeFilter"
            @click="$refs['copy-to-store-modal'].dialog = true"
            >Kopioi</v-btn
          >
          <v-autocomplete
            :items="productGroupChoices"
            v-model="selectedFilter['productgroup']"
            dense
            hide-details="auto"
            label="Tuoteryhmä"
            class="mr-4 ml-4 pt-4"
          >
          </v-autocomplete>
          <v-select
            :items="extraColumnChoices"
            v-model="extraColumns"
            dense
            multiple
            hide-details="auto"
            label="Sarakkeet"
            class="mr-4 ml-4 pt-4"
          >
            <template v-slot:selection="{ index }">
              <span v-if="index == 0">
                {{ extraColumns.length - 1 }} saraketta
              </span>
            </template>
          </v-select>
          <input
            style="width: 2rem"
            type="text"
            v-model="viewMode.perPage"
            title="Tuotteita per sivu"
          />
          <div class="mr-4"></div>
          <v-btn
            :disabled="viewMode.page == 1"
            @click="
              loadingWrapper(true).then(() => {
                viewMode.page -= 1;
                if (viewMode.page < 1) {
                  viewMode.page = 1;
                }
              })
            "
            >Edellinen</v-btn
          >
          <v-btn
            >({{ pageText }} / {{ Object.keys(colorGroups).length }})</v-btn
          >
          <v-btn
            :disabled="viewMode.page == viewMode.maxPages"
            @click="
              loadingWrapper(true).then(() => {
                viewMode.page += 1;
                if (viewMode.page > viewMode.maxPages) {
                  viewMode.page = viewMode.maxPages;
                }
              })
            "
            >Seuraava</v-btn
          >
        </v-toolbar-items>
      </v-toolbar>
    </v-row>
    <v-row>
      <v-col class="col-2">
        <v-text-field
          v-model="selectedFilter['productName']"
          dense
          hide-details="auto"
          label="Tuotehaku"
          class="mr-4 ml-4 pt-4"
        >
        </v-text-field>
      </v-col>
      <v-col v-if="order" class="col-2">
        <a
          target="_blank"
          :href="`/admin/products/size/?supplier__id__exact=${order.supplier}`"
          >Kokojen järjestäminen</a
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-2">
        <h2 v-if="storeFilter">
          {{ (storesById[storeFilter] || {}).name }}
        </h2>
      </v-col>
      <v-col v-if="storeFilter">
        Lisää toimitusvko kaikkiin tuotteisiin
        <v-btn
          class="ml-auto"
          icon
          @click="addDeliveryToAll()"
          title="Lisää toimitusvko kaikkiin tuotteisiin"
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <div style="margin-left: -96px">
      <horizontal-drag-input v-model="tableWidths" />
    </div>
    <v-row>
      <v-col
        v-if="!order || !order.groups || hideTable"
        class="d-flex col-12 justify-center"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
      <v-col :class="{ hidden: hideTable }">
        <table
          v-for="(
            { sizeGroupId, sizeGroupColorGroups }, sizeGroupIndex
          ) in paginatedSizeGroups"
          :key="`${sizeGroupId}-${sizeGroupIndex}`"
          dense
          cellpadding="0"
          cellspacing="0"
        >
          <thead v-if="!_.isEmpty(sizeGroupColorGroups)">
            <tr>
              <!-- Start "Left side" -->
              <th
                :style="{ width: cellWidthsBySizeGroup[sizeGroupId].left[0] }"
              >
                <order-by-header
                  v-if="sizeGroupIndex == 0"
                  :col="{ text: 'Tuote', value: 'name' }"
                  :setOrderBy="setOrderBy"
                  :orderBy="orderBy"
                ></order-by-header>
                <v-btn
                  icon
                  @click="expandToggleAllColorGroups()"
                  v-if="!storeFilter && sizeGroupIndex == 0"
                  ><v-icon v-if="viewMode.expandedColorGroups !== null"
                    >mdi-chevron-down</v-icon
                  ><v-icon v-else>mdi-chevron-up</v-icon></v-btn
                >
                <a
                  target="_blank"
                  :href="`/admin/products/size/?supplier__id__exact=${
                    order.supplier
                  }&id__in=${sizesByGroup[sizeGroupId]
                    .map((size) => size.id)
                    .join(',')}`"
                  >Järjestä koot</a
                >
              </th>
              <th
                v-for="(extraCol, extraColIndex) in startExtraColumns"
                :key="extraCol.value"
                :style="{
                  width:
                    cellWidthsBySizeGroup[sizeGroupId].left[extraColIndex + 1],
                }"
              >
                <order-by-header
                  v-if="extraCol.sortable"
                  :col="extraCol"
                  :setOrderBy="setOrderBy"
                  :orderBy="orderBy"
                ></order-by-header>
                <span v-else>{{ extraCol.text }}</span>
              </th>
              <th
                v-if="!storeFilter"
                :style="{
                  width: '120px',
                }"
              >
                varasto
              </th>
              <!-- End "Left side" -->
              <!-- Start "Middle" -->
              <th
                :style="{
                  width: '50px',
                }"
              >
                Vko
              </th>
              <th
                v-for="(size, sizeByGroupIndex) in sizesByGroup[sizeGroupId]"
                :key="`th-${size.id}`"
                :style="{
                  width:
                    cellWidthsBySizeGroup[sizeGroupId].center[sizeByGroupIndex],
                }"
              >
                <thin-span :text="size.name"></thin-span>
              </th>
              <!-- End "Middle" -->
              <!-- Start "Right side" -->
              <th
                v-for="(extraCol, extraColIndex) in endExtraColumns"
                :key="extraCol.value"
                :style="{
                  width:
                    cellWidthsBySizeGroup[sizeGroupId].right[extraColIndex],
                }"
              >
                <order-by-header
                  v-if="extraCol.sortable"
                  :col="extraCol"
                  :setOrderBy="setOrderBy"
                  :orderBy="orderBy"
                ></order-by-header>
                <span v-else>{{ extraCol.text }}</span>
              </th>
              <!-- End "Right side" -->
            </tr>
          </thead>
          <tbody v-if="sizeGroupIndex == 0">
            <!-- Additional first row with global totals -->
            <tr>
              <td class="start-td"><strong>Summat</strong></td>
              <td
                class="start-td empty-td"
                v-for="extraCol in startExtraColumns"
                :key="extraCol.value"
              ></td>
              <td v-if="!storeFilter" class="start-td empty-td"></td>
              <td class="empty-td"></td>
              <td
                v-for="size in sizesByGroup[sizeGroupId]"
                :key="`td-${size.id}`"
                class="empty-td"
              ></td>
              <td v-for="extraCol in endExtraColumns" :key="extraCol.value">
                <cell-input
                  v-if="extraCol.value in totalSums"
                  disabled
                  :value="totalSums[extraCol.value]"
                />
              </td>
            </tr>
          </tbody>
          <disappearing-t-body
            v-for="colorGroup in sizeGroupColorGroups"
            :key="`tbody-${colorGroup.color_group_str}`"
            :class="`tbody-${colorGroup.color_group_str}`"
            :initial-height="`${100 + sortedDateGroups(colorGroup).length * 50}px`"
          >
            <template v-slot:empty
              ><b style="font-size: 13px">{{ colorGroup.name }}</b></template
            >
            <template>
              <tr
                v-if="!storeFilter"
                :class="{
                  'linkedgroup-highlight':
                    colorGroup.color_group_str === linkedGroupLocal,
                }"
              >
                <td class="start-td">
                  <b style="font-size: 13px">{{ colorGroup.name }}</b>
                  <v-btn
                    icon
                    @click="expandToggleColorGroup(colorGroup)"
                    v-if="!storeFilter"
                    ><v-icon
                      v-if="
                        viewMode.expandedColorGroups !== null &&
                        !(
                          colorGroup.color_group_str in
                          viewMode.expandedColorGroups
                        )
                      "
                      >mdi-chevron-down</v-icon
                    ><v-icon v-else>mdi-chevron-up</v-icon></v-btn
                  >
                  <edit-comments
                    :selected-products="getSelectedProducts(colorGroup)"
                    :choices="choices"
                  >
                  </edit-comments>

                  <v-btn
                    class="ml-auto"
                    icon
                    @click="addColorGroupDelivery(colorGroup)"
                    title="Luo rivit uudella toimitusviikolla kaikkiin varastoihin"
                    ><v-icon>mdi-plus</v-icon></v-btn
                  >
                </td>
                <td
                  class="start-td"
                  v-for="extraCol in startExtraColumns"
                  :key="extraCol.value"
                >
                  {{
                    getSelectedProducts(colorGroup)[0].product[extraCol.value]
                  }}
                </td>
                <td class="empty-td"></td>
                <td class="start-td empty-td"></td>
                <td
                  class="input-number-cell size-td empty-td"
                  :class="{
                    'size-td-first': sizeIndex == 0,
                    'size-td-last':
                      sizeIndex == sizesByGroup[sizeGroupId].length - 1,
                  }"
                  v-for="(size, sizeIndex) in sizesByGroup[sizeGroupId]"
                  :key="`td-${size.id}`"
                ></td>
                <extra-column
                  :colorGroup="colorGroup"
                  :extraCol="extraCol"
                  :order="order"
                  v-for="extraCol in endExtraColumns"
                  :key="extraCol.value"
                >
                </extra-column>
              </tr>
              <tr
                v-if="
                  storeFilter &&
                  sortedDateGroups(colorGroup).filter(
                    (dateGroup) => !dateGroup.hidden
                  ).length == 0
                "
              >
                <td>
                  <div class="d-flex">
                    <b style="font-size: 13px">{{ colorGroup.name }}</b>
                    <div class="ml-auto">
                      <v-btn
                        v-if="storeFilter"
                        class="ml-auto"
                        icon
                        @click="
                          addDelivery(
                            sortedDateGroups(colorGroup)[0],
                            sortedDateGroups(colorGroup)[0].store
                          )
                        "
                        :title="`Luo rivi uudella toimitusviikolla varastoon ${
                          storesById[sortedDateGroups(colorGroup)[0].store].name
                        }`"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </div>
                  </div>
                </td>
                <extra-column
                  :dateGroup="sortedDateGroups(colorGroup)[0]"
                  :colorGroup="colorGroup"
                  :extraCol="extraCol"
                  :order="order"
                  v-for="extraCol in startExtraColumns"
                  :key="extraCol.value"
                  class="start-td"
                ></extra-column>
                <td class="empty-td"></td>
                <td
                  :colspan="sizesByGroup[sizeGroupId].length"
                  style="background-color: #cccccc"
                ></td>
                <extra-column
                  :dateGroup="sortedDateGroups(colorGroup)[0]"
                  :colorGroup="colorGroup"
                  :extraCol="extraCol"
                  :order="order"
                  v-for="extraCol in endExtraColumns"
                  :key="extraCol.value"
                ></extra-column>
              </tr>
              <tr
                v-for="dateGroup in sortedDateGroups(colorGroup)"
                :key="`${colorGroup.color_group_str}-${dateGroup.date}-${
                  Object.values(dateGroup.groups)[0].store
                }`"
                :class="{ 'dategroup-hidden': dateGroup.hidden }"
              >
                <td
                  class="start-td"
                  v-if="
                    viewMode.expandedColorGroups === null ||
                    viewMode.expandedColorGroups.indexOf(
                      colorGroup.color_group_str
                    ) > -1 // Hide these when not expanded
                  "
                >
                  <div class="d-flex">
                    <div v-if="storeFilter" style="max-height: 1rem">
                      <!-- Show product name here if storeFilter mode, since the previous row is hidden -->
                      <b style="font-size: 13px">{{ colorGroup.name }}</b>
                    </div>
                    <div class="ml-auto">
                      <date-group-edit
                        :date-group="dateGroup"
                        :store-group="colorGroup.groups[dateGroup.store]"
                        :order="order"
                      >
                        <template v-slot="{ clearFunc }">
                          <v-btn
                            v-if="showAddDeliveryBtn(dateGroup.store)"
                            class="ml-auto"
                            icon
                            @click="addDelivery(dateGroup, dateGroup.store)"
                            :title="`Luo rivi uudella toimitusviikolla varastoon ${
                              (storesById[dateGroup.store] || {}).name
                            }`"
                            ><v-icon>mdi-plus</v-icon></v-btn
                          >
                          <v-btn
                            class="ml-auto"
                            icon
                            @click="clearFunc()"
                            title="Poista rivi"
                            ><v-icon>mdi-delete</v-icon></v-btn
                          >
                        </template>
                      </date-group-edit>
                    </div>
                  </div>
                </td>
                <extra-column
                  :dateGroup="dateGroup"
                  :colorGroup="colorGroup"
                  :extraCol="extraCol"
                  :order="order"
                  v-for="extraCol in startExtraColumns"
                  :key="extraCol.value"
                  class="start-td"
                ></extra-column>
                <td class="start-td" v-if="!storeFilter">
                  <span>{{ (storesById[dateGroup.store] || {}).name }}</span>
                </td>
                <selectable-cell>
                  <date-group-edit
                    :date-group="dateGroup"
                    :store-group="colorGroup.groups[dateGroup.store]"
                    :order="order"
                  >
                    <template>
                      <edit-color-group-date
                        :color-group="colorGroup"
                        :date-group-index="dateGroup.index"
                        :date-group-store="dateGroup.store"
                        :order="order"
                        @updated="loadReactiveRows()"
                      >
                        <template
                          v-slot="{ setDeliveryDateFunc, selectedDate }"
                        >
                          <input
                            style="width: 25px"
                            type="text"
                            :value="selectedDate"
                            @change="setDeliveryDateFunc($event.target.value)"
                          />
                        </template>
                      </edit-color-group-date>
                    </template>
                  </date-group-edit>
                </selectable-cell>
                <selectable-cell
                  class="input-number-cell size-td"
                  :class="{
                    'size-td-first': sizeIndex == 0,
                    'size-td-last':
                      sizeIndex == sizesByGroup[sizeGroupId].length - 1,
                  }"
                  v-for="(size, sizeIndex) in sizesByGroup[sizeGroupId]"
                  :key="`td-${size.id}`"
                  :row="dateGroup.groups[size.id]"
                >
                  <order-row-edit
                    :row="
                      rowsByRowString[
                        dateGroup.groups[size.id]?.store_date_size_color_group_str
                      ]
                    "
                    :reactive-row="dateGroup.groups[size.id]"
                    @changed="debouncedloadNonReactiveRows()"
                  >
                    <template v-slot="{ newRow, updateFunc }">
                      <cell-input
                        size="3"
                        type="text"
                        :required="true"
                        v-model="newRow.quantity"
                        @keydown="updateFunc()"
                      />
                    </template>
                  </order-row-edit>
                </selectable-cell>

                <extra-column
                  :dateGroup="dateGroup"
                  :colorGroup="colorGroup"
                  :extraCol="extraCol"
                  :order="order"
                  v-for="extraCol in endExtraColumns"
                  :key="extraCol.value"
                ></extra-column>
              </tr>
              <tr v-if="!storeFilter">
                <!-- Each store + date summed for this size (counts) -->
                <td class="empty-td"></td>
                <td
                  v-for="extraCol in startExtraColumns"
                  :key="extraCol.value"
                  class="empty-td"
                >
                  <span v-if="extraCol.value == 'raw_color'"></span>
                </td>
                <td>Yhteensä</td>
                <td class="empty-td"></td>
                <td
                  class="input-number-cell"
                  v-for="size in sizesByGroup[sizeGroupId]"
                  :key="`td-${size.id}`"
                >
                  <cell-input
                    size="3"
                    type="text"
                    :disabled="true"
                    :value="`${getSizeGlobalTotals(colorGroup, size).quantity}`"
                  />
                </td>
                <td v-for="extraCol in endExtraColumns" :key="extraCol.value">
                  <cell-input
                    v-if="extraCol.value == 'quantity_sum'"
                    disabled
                    :value="getColorGroupGlobalTotals(colorGroup).quantity"
                  />
                  <cell-input
                    v-else-if="extraCol.value == 'expense_sum'"
                    disabled
                    :value="getColorGroupGlobalTotals(colorGroup).net_price"
                  />
                  <cell-input
                    v-else-if="extraCol.value == 'revenue_sum'"
                    disabled
                    :value="getColorGroupGlobalTotals(colorGroup).list_price"
                  />

                  <cell-input
                    v-else-if="extraCol.value == 'profit_sum'"
                    disabled
                    :value="getColorGroupGlobalTotals(colorGroup).profit"
                  />
                  <span v-else></span>
                </td>
              </tr>
            </template>
          </disappearing-t-body>
        </table>
      </v-col>
      <v-col v-if="!tbodyRender.index" class="d-flex col-12 justify-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row v-if="paginatedSizeGroups.length > 0">
      <v-col class="col-9"></v-col>
      <v-col class="col-3">
        <v-btn
          :disabled="viewMode.page == 1"
          @click="
            loadingWrapper(true).then(() => {
              viewMode.page -= 1;
              if (viewMode.page < 1) {
                viewMode.page = 1;
              }
            })
          "
          >Edellinen</v-btn
        >
        <v-btn>({{ pageText }} / {{ Object.keys(colorGroups).length }})</v-btn>
        <v-btn
          :disabled="viewMode.page == viewMode.maxPages"
          @click="
            loadingWrapper(true).then(() => {
              viewMode.page += 1;
              if (viewMode.page > viewMode.maxPages) {
                viewMode.page = viewMode.maxPages;
              }
            })
          "
          >Seuraava</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import CopyToStoreModal from "@/components/CopyToStoreModal.vue";
import OrderRowEdit from "@/components/OrderRowEdit.vue";
import DateGroupEdit from "@/components/DateGroupEdit.vue";
import CellInput from "@/components/CellInput.vue";
import SelectableCell from "../components/SelectableCell.vue";
import EditColorGroupDate from "../components/EditColorGroupDate.vue";
import OrderByHeader from "../components/OrderByHeader.vue";
import ExtraColumn from "../components/ExtraColumn.vue";
import HorizontalDragInput from "../components/HorizontalDragInput.vue";
import EditComments from "../components/EditComments.vue";
import PriceMismatchModal from "../components/PriceMismatchModal.vue";
import ShiftPainter from "../components/ShiftPainter.vue";
import DisappearingTBody from "../components/DisappearingTBody.vue";
import ThinSpan from "../components/ThinSpan.vue";

import stickyTHEAD from "../mixins/StickyTHEAD.vue";
import ChoicesOrder from "../mixins/ChoicesOrder.vue";
import QuerySave from "../mixins/QuerySave.vue";
import ColorGroupCalculations from "../mixins/ColorGroupCalculations.vue";
import OrderHelpers from "../mixins/OrderHelpers.vue";

import { cloneDeep, debounce } from "lodash";

export default {
  name: "ManageOrder",
  components: {
    CopyToStoreModal,
    OrderRowEdit,
    DateGroupEdit,
    CellInput,
    SelectableCell,
    EditColorGroupDate,
    OrderByHeader,
    ExtraColumn,
    HorizontalDragInput,
    EditComments,
    PriceMismatchModal,
    ShiftPainter,
    DisappearingTBody,
    ThinSpan,
  },
  mixins: [
    stickyTHEAD,
    ChoicesOrder,
    QuerySave,
    ColorGroupCalculations,
    OrderHelpers,
  ],
  data: () => ({
    order: null,
    colorGroups: [],
    storeFilter: null,
    hideTable: false,
    querySave: [
      "storeFilter",
      "selectedFilter",
      "extraColumns",
      "orderBy",
      "tableWidths",
    ],
    selectedFilter: {},
    orderBy: ["-selectedproduct__color_group"],
    extraColumns: [
      "start:color__name",
      "end:quantity_sum",
      "end:purchase_price",
      "end:net_price",
      "end:expense_sum",
      "end:list_price",
    ],
    extraColumnChoices: [
      {
        value: "start:color__name",
        text: "Väri",
        sortable: true,
        weight: 0.4, // weighted width
      },
      {
        value: "start:type__name",
        text: "Tyyppi",
        sortable: true,
        weight: 0.3, // weighted width
      },
      {
        value: "start:raw_color",
        text: "Värikoodi",
        sortable: true,
      },
      {
        value: "start:raw_main_group",
        text: "Tuoteryhmäkoodi",
        sortable: true,
      },
      {
        value: "start:modelnumber",
        text: "Mallinumero",
        sortable: true,
      },
      {
        value: "start:raw_property_division",
        text: "Division",
        sortable: true,
      },
      {
        value: "start:raw_property_concept",
        text: "Concept",
        sortable: true,
      },
      {
        value: "start:raw_property_family",
        text: "Family",
        sortable: true,
      },
      {
        value: "end:list_price",
        text: "Myyntihinta",
        sortable: true,
        order: 4,
      },
      {
        value: "end:purchase_price",
        text: "NETTO",
        sortable: true,
        order: 1,
      },
      {
        value: "end:net_price",
        text: "ENETTO",
        sortable: true,
        order: 2,
      },
      {
        value: "end:campaign_price",
        text: "Kampanjahinta",
        sortable: true,
      },
      {
        value: "end:profit",
        text: "Kate",
        sortable: true,
      },
      {
        value: "end:profit_percentage",
        text: "Kateprosentti",
        sortable: true,
      },
      {
        value: "end:quantity_sum",
        text: "Summa (kpl)",
        order: 0,
      },
      {
        value: "end:profit_sum",
        text: "Katesumma",
      },
      {
        value: "end:revenue_sum",
        text: "Tulosumma",
      },
      {
        value: "end:expense_sum",
        text: "Yhteensä",
        order: 3,
      },
    ],
    viewMode: {
      editableDates: false,
      expandedColorGroups: null, // null means all are expanded
      page: 1,
      maxPages: 1,
      perPage: 250,
    },
    tableWidths: {
      left: 900,
      right: 1400,
    },
    tbodyRender: {
      index: 0, // 0 = hide, 1 = show
    },
    orderLoaded: false,
    watcherDisabled: {
      viewMode: false,
    },
    linkedGroupLocal: null,
  }),
  mounted() {
    this.$store.commit('disappearingHelper/clearComponentVisible');
    this.loadingWrapper().then(() => {
      this.fetchOrder();
    });
  },
  computed: {
    debouncedloadNonReactiveRows() {
      return debounce(this.loadNonReactiveRows, 1000);
    },
    rowsByRowString() {
      if (!this.orderLoaded) {
        return {};
      }
      return this._rowsByRowString || {};
    },
    perPage() {
      if (this.storeFilter) {
        return this.viewMode.perPage * 5;
      }
      return this.viewMode.perPage;
    },
    slowDebouncedRedraw() {
      return this._.debounce(() => {
        console.log("Start debouncedRedraw");
        this.loadingWrapper().then(this.refreshColorGroups);
      }, 500);
    },
    debouncedRedraw() {
      return this._.debounce(() => {
        console.log("Start debouncedRedraw");
        this.loadingWrapper().then(this.refreshColorGroups);
      }, 100);
    },
    lodash() {
      return this._;
    },
    selectedExtraColumns() {
      /* eslint-disable vue/no-side-effects-in-computed-properties */
      const ret = this.extraColumns.map((col) => {
        let type, val;
        if (col.search(/start:/) === 0) {
          type = "start";
          val = col.replace(/^start:/, "");
        } else if (col.search(/end:/) === 0) {
          type = "end";
          val = col.replace(/^end:/, "");
        }

        const colChoice = this.extraColumnChoices.filter((c) =>
          c.value.includes(val)
        )[0];
        return {
          ...colChoice,
          type: type,
          value: val,
        };
      });
      return this._.sortBy(ret, (col) => col.order);
    },
    startExtraColumns() {
      return this.selectedExtraColumns.filter((col) => col.type === "start");
    },
    endExtraColumns() {
      return this.selectedExtraColumns.filter((col) => col.type === "end");
    },
    productGroupChoices() {
      const ret = [
        {
          value: "",
          text: "",
        },
      ];
      const usedGroups = new Set();
      if (this.order) {
        for (const selectedproduct of Object.values(
          this.order.selectedproducts
        )) {
          usedGroups.add(selectedproduct.product.main_group);
        }
      }
      for (const productgroup of this.choices.productgroups || []) {
        if (usedGroups.has(productgroup.id)) {
          ret.push({
            value: productgroup.id,
            text: productgroup.name,
          });
        }
      }
      return ret;
    },
    colorGroupToPage() {
      // Return a page number for each colorgroupstr
      const ret = {};
      for (const colorGroup of this.colorGroups) {
        ret[colorGroup.color_group_str] = colorGroup.onPage;
      }
      return ret;
    },
    pageText() {
      // e.g "1-30"

      const first = (this.viewMode.page - 1) * this.perPage + 1;
      let second = this.viewMode.page * this.perPage;
      if (second > Object.keys(this.colorGroups).length) {
        second = Object.keys(this.colorGroups).length;
      }
      return `${first}-${second}`;
    },
    colorGroupsOnThisPage() {
      return this.colorGroups.filter(
        (colorGroup) =>
          this.colorGroupToPage[colorGroup.color_group_str] ==
          this.viewMode.page
      );
    },
    paginatedSizeGroups() {
      // Return a list of sizegroupsids for this page, each including a list
      // of colorGroups
      // NOTE: each sizegroup id can appear multiple times in the list
      let i = 0;
      const ret = [];
      if (!this.tbodyRender.index) {
        return ret;
      }
      for (const _colorGroup of this.colorGroupsOnThisPage) {
        for (const sizeGroupId of this.sizeGroups) {
          if (_colorGroup.size__group == sizeGroupId) {
            if (
              ret.length > 0 &&
              ret[ret.length - 1].sizeGroupId == sizeGroupId
            ) {
              // No need to create a new table with headers every time if the last size
              // group was the same
              ret[ret.length - 1].sizeGroupColorGroups.push(_colorGroup);
            } else {
              ret.push({ sizeGroupId, sizeGroupColorGroups: [_colorGroup] });
            }
          }
        }
        i += 1;
      }
      return ret;
    },
    storesById() {
      let ret = {};
      if (!this.choices || !this.choices.stores) {
        return ret;
      }
      this.choices.stores.forEach((store) => {
        ret[store.id] = store;
      });
      return ret;
    },
    storeFilterChoices() {
      return [{ value: null, text: "Kaikki" }].concat(
        (this.choices.stores || []).map((row) => ({
          value: row.id,
          text: row.name,
        }))
      );
    },
    cellWidthsBySizeGroup() {
      const ret = {};
      for (const { sizeGroupId } of this.paginatedSizeGroups) {
        const left = this.tableWidths.left;
        const center = this.tableWidths.right - this.tableWidths.left;
        const right = document.body.offsetWidth - this.tableWidths.right;

        ret[sizeGroupId] = {
          left: [],
          center: [],
          right: [],
        };

        const weightedSplit = (items, total) => {
          // Given a list of items, return a list of equal length
          // containing values that sum to total px
          // items can have attributes
          // `.weight` (default: 1.0)
          // `.width`  (default: null)

          const weighteds = items.filter((i) => !i.width);
          const weightSum = weighteds.reduce((a, v) => a + (v.weight || 1), 0);
          // Substract static values from total
          const weightedTotal = items
            .filter((i) => !!i.width)
            .reduce((a, v) => a - v.width, total);
          const weightedBase = weightedTotal / weightSum;

          const ret = [];
          let sum = 0;
          for (const item of items) {
            if (item.width) {
              ret.push(item.width + "px");
              sum += item.width;
            } else {
              ret.push(weightedBase * (item.weight || 1) + "px");
              sum += weightedBase * (item.weight || 1);
            }
          }
          return ret;
        };

        // left
        ret[sizeGroupId].left = weightedSplit(
          [{}, ...this.startExtraColumns, { weight: 0.5 }],
          left
        );

        // center
        const sizeCount = this.sizesByGroup[sizeGroupId].length;
        const centerCellWidth = center / sizeCount;
        for (let i = 0; i < sizeCount; i++) {
          ret[sizeGroupId].center.push(centerCellWidth + "px");
        }

        // right
        ret[sizeGroupId].right = weightedSplit(this.endExtraColumns, right);
      }
      return ret;
    },
    totalSums() {
      console.log("getting totalSums...");
      let ret;
      for (const colorGroup of this.colorGroups) {
        for (const storeGroup of Object.values(colorGroup.groups)) {
          if (this.storeFilter === null || this.storeFilter === storeGroup.id) {
            for (const dateGroup of Object.values(storeGroup.groups)) {
              for (const row of Object.values(dateGroup.groups)) {
                ret = this.addRowPrices(row, ret);
              }
            }
          }
        }
      }
      console.log("getting totalSums...done!");
      return {
        quantity_sum: ret.quantity,
        expense_sum: ret.net_price,
        revenue_sum: ret.list_price,
        profit_sum: ret.profit,
      };
    },
  },
  methods: {
    withoutWatcher(key, callback) {
      // Run callback without watcher
      const old = this.watcherDisabled[key];
      this.watcherDisabled[key] = true;
      callback();
      this.$nextTick(() => {
        if (this.watcherDisabled[key] === true) {
          this.watcherDisabled[key] = old;
        }
      });
    },
    loadReactiveRows() {
      this.orderLoaded = false;
      this._rowsByRowString = {};
      for (const colorGroup of Object.values(this.order.groups)) {
        for (const store of Object.values(colorGroup.groups)) {
          for (const dateGroup of Object.values(store.groups)) {
            for (const row of Object.values(dateGroup.groups)) {
              this.$set(
                this._rowsByRowString,
                row.store_date_size_color_group_str,
                cloneDeep(row)
              );
            }
          }
        }
      }
      this.orderLoaded = true;
    },
    loadNonReactiveRows() {
      // Load values from rowsByRowString into this.order
      for (const row of Object.values(this.rowsByRowString)) {
        const reactiveRow =
          this.order.groups[row.color_group_str].groups[row.store].groups[
            row.delivery_date
          ].groups[row.size];
        if (reactiveRow.quantity == "DELETE") {
          row.quantity = "DELETE";
        }
        reactiveRow.quantity = row.quantity;
        reactiveRow.id = row.id;
      }
    },
    refreshColorGroups() {
      /* eslint-disable vue/no-side-effects-in-computed-properties */
      console.log("refreshColorGroups start");

      this.stickyTHEAD = null;
      if (!this.order || !this.order.groups) {
        return [];
      }
      const productGroupFilter = this.selectedFilter.productgroup;
      const productNameFilter = this.selectedFilter.productName;
      const ret = this._.sortBy(
        Object.values(this.order.groups),
        this.orderBy.filter(Boolean).map((key) => {
          return (colorGroup) => {
            const ret = this.colorGroupKeyGetter(
              colorGroup,
              key ? key.replace(/^-/, "") : key
            );
            return ret;
          };
        })
      ).filter((colorGroup) => {
        if (productGroupFilter) {
          const product = this.getSelectedProducts(colorGroup)[0].product;
          if (productGroupFilter != product.main_group) {
            console.log("Filtered " + product);
            return false;
          }
        }
        if (productNameFilter) {
          const product = this.getSelectedProducts(colorGroup)[0].product;
          if (
            !product.name
              .toLowerCase()
              .includes(productNameFilter.toLowerCase())
          ) {
            console.log("Filtered " + product);
            return false;
          }
        }
        return true;
      });
      if (this.orderBy[0] && this.orderBy[0][0] != "-") {
        this._.reverse(ret);
      }

      let counter = 0;
      let page = 1;
      for (const colorGroup of ret) {
        colorGroup.onPage = page;
        counter += 1;
        if (counter >= this.perPage) {
          counter = 0;
          page += 1;
        }
      }
      this.withoutWatcher("viewMode", () => {
        this.viewMode.maxPages = page;
      });

      console.log("refreshColorGroups finished");
      // Apply additional filters/sortings here
      this.colorGroups.splice(0, this.colorGroups.length, ...ret);
    },
    setOrderBy(col, col2) {
      if (this.orderBy[0] == col) {
        col = "-selectedproduct__color_group"; // DEFAULTS!
        col2 = null;
      } else if (this.orderBy[0] == "-" + col) {
        col = col.slice();
      } else {
        col = "-" + col;
      }
      this.loadingWrapper(true).then(() => {
        this.orderBy.splice(0, this.orderBy.length, col, col2);
      });
    },
    loadingWrapper(hideOnly) {
      // hide the table temporarily and return a promise that resolves when the table is hidden
      this.tbodyRender.index = 0;

      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          if (!hideOnly) {
            this.tbodyRender.index = 1;
          }
          resolve();
        });
      });
    },
    refreshTable() {
      this.hideTable = false;
      this.$nextTick(() => {
        this.hideTable = false;
      });
    },
    expandToggleAllColorGroups() {
      this.withoutWatcher("viewMode", () => {
        if (this.viewMode.expandedColorGroups === null) {
          Vue.set(this.viewMode, "expandedColorGroups", []);
        } else {
          Vue.set(this.viewMode, "expandedColorGroups", null);
        }
      });
    },
    expandToggleColorGroup(colorGroup) {
      this.withoutWatcher("viewMode", () => {
        if (this.viewMode.expandedColorGroups === null) {
          Vue.set(
            this.viewMode,
            "expandedColorGroups",
            this.colorGroups
              .filter((cg) => cg.color_group_str !== colorGroup.color_group_str)
              .map((cg) => cg.color_group_str)
          );
        } else {
          if (
            this.viewMode.expandedColorGroups.indexOf(
              colorGroup.color_group_str
            ) === -1
          ) {
            this.viewMode.expandedColorGroups.push(colorGroup.color_group_str);
          } else {
            this.$delete(
              this.viewMode.expandedColorGroups,
              this.viewMode.expandedColorGroups.indexOf(
                colorGroup.color_group_str
              )
            );
          }
        }
      });
    },
    sortedDateGroups(colorGroup) {
      // Given a colorGroup, this will return a list of dateGroups.
      // So basically store is dropped from in between.
      if (
        this.viewMode.expandedColorGroups !== null &&
        this.viewMode.expandedColorGroups.indexOf(
          colorGroup.color_group_str
        ) === -1
      ) {
        return [];
      }
      const shownDates = new Set();
      const ret = this._.sortBy(
        Object.values(colorGroup.groups).reduce((acc, store) => {
          if (this.storeFilter !== null && this.storeFilter !== store.id) {
            return acc;
          }
          return this._.sortBy(Object.values(store.groups), ["date"])
            .map((_dateGroup, index) => {
              // Store some useful values
              _dateGroup.index = index;
              _dateGroup.store = store.id;
              _dateGroup.store__sort_order = store.sort_order;
              _dateGroup.hidden = Boolean(
                _dateGroup.date == "0" ||
                  (Object.values(_dateGroup.groups)[0] || {}).quantity ==
                    "DELETE"
              );
              return _dateGroup;
            })
            .concat(acc);
        }, []),
        ["date", "store__sort_order"]
      );
      const ret2 = ret.map((dateGroup) => {
        if (!shownDates.has(dateGroup.date)) {
          dateGroup.firstDateGroup = true;
        } else {
          dateGroup.firstDateGroup = false;
        }
        shownDates.add(dateGroup.date);
        return dateGroup;
      });
      return ret2;
    },
    fetchOrder() {
      return this.axios
        .get(
          "/api/orders/order/" +
            this.$route.params.orderId +
            "/?grouping=color_group,store,date"
        )
        .then((resp) => {
          this.order = resp.data;
          if (
            this.order.types &&
            !this.extraColumns.includes("start:type__name")
          ) {
            this.extraColumns.push("start:type__name");
          }
          if (this.storeFilter) {
            this.viewMode.expandedColorGroups = null;
            this.viewMode.editableDates = false;
            this.page = 1;
          }
          this.loadReactiveRows();
          this.refreshColorGroups();
        });
    },
    showAddDeliveryBtn(store) {
      for (const date in store.groups) {
        if (date == "null") {
          return false;
        }
      }
      return true;
    },
    getTotalPlannedQuantityText(colorGroup) {
      const row = Object.values(colorGroup.groups)[0];
      return this.getPlannedQuantityText(row, "color_group_quantity");
    },
    getPlannedQuantityText(row, key) {
      if (key == undefined) {
        key = "quantity";
      }
      if (!row.selectedproduct) {
        return "";
      }
      if (!this.order.selectedproducts[row.selectedproduct]) {
        return "";
      }
      if (this.order.selectedproducts[row.selectedproduct][key] == undefined) {
        return "";
      }
      return (
        "Suunniteltu: " + this.order.selectedproducts[row.selectedproduct][key]
      );
    },
    addDeliveryToAll(newWeek) {
      if (!this.storeFilter) {
        alert("Valitse varasto");
        return;
      }
      if (newWeek == undefined) {
        newWeek = window.prompt(
          "Anna uusi viikkonumero (Huom! tämä tulee kaikkiin tuotteisiin!). Voit antaa useamman pilkulla eroteltuna."
        );
        if (newWeek) {
          newWeek = newWeek.trim();
        }
        // comma separation
        if (newWeek.includes(",")) {
          const newWeeksArray = newWeek.split(",").map((s) => s.trim());
          for (const newWeekPart of newWeeksArray) {
            if (
              !newWeekPart ||
              isNaN(newWeekPart) ||
              parseInt(newWeekPart) < 1 ||
              parseInt(newWeekPart) > 53
            ) {
              alert("Virheellinen viikkonumero: " + newWeekPart);
              return;
            }
          }
          for (const newWeekPart of newWeeksArray) {
            this.addDeliveryToAll(newWeekPart);
          }
          return;
        }
        if (
          !newWeek ||
          isNaN(newWeek) ||
          parseInt(newWeek) < 1 ||
          parseInt(newWeek) > 53
        ) {
          alert("Virheellinen viikkonumero: " + newWeek);
          return;
        }
      }
      for (const colorGroup of this.colorGroups) {
        for (const dateGroup of this.sortedDateGroups(colorGroup)) {
          this.addDelivery(dateGroup, this.storeFilter, newWeek);
        }
      }
    },
    addDelivery(dateGroup, storeID, newWeek) {
      // For each colorGroup, prompt and create a new delivery week
      // for this stock
      // Create new rows with dateGroup "null".
      const justARow = Object.values(dateGroup.groups)[0];
      if (newWeek == undefined) {
        newWeek = window.prompt(
          "Anna uusi viikkonumero. Voit antaa useamman pilkulla eroteltuna."
        );
        if (newWeek) {
          newWeek = newWeek.trim();
        }
        // comma separation
        if (newWeek.includes(",")) {
          const newWeeksArray = newWeek.split(",").map((s) => s.trim());
          for (const newWeekPart of newWeeksArray) {
            if (
              !newWeekPart ||
              isNaN(newWeekPart) ||
              parseInt(newWeekPart) < 1 ||
              parseInt(newWeekPart) > 53
            ) {
              alert("Virheellinen viikkonumero: " + newWeekPart);
              return;
            }
          }
          for (const newWeekPart of newWeeksArray) {
            this.addDelivery(dateGroup, storeID, newWeekPart);
          }
          return;
        }
        if (
          !newWeek ||
          isNaN(newWeek) ||
          parseInt(newWeek) < 1 ||
          parseInt(newWeek) > 53
        ) {
          alert("Virheellinen viikkonumero: " + newWeek);
          return;
        }
      }
      if (Number.isNaN(newWeek)) {
        return;
      }
      Vue.set(
        this.order.groups[justARow.color_group_str].groups[storeID].groups,
        newWeek,
        {
          isNew: true,
          type: "date",
          date: newWeek,
          groups: Object.fromEntries(
            Object.entries(dateGroup.groups)
              .filter(([size_id, row]) => row.store == storeID)
              .map(([size_id, row]) => {
                const copiedRow = this._.cloneDeep(row);
                copiedRow.id = null;
                copiedRow.delivery_date = newWeek;
                copiedRow.store_date_size_color_group_str = `${row.store}_${newWeek}_${row.size}_${row.color_group_str}`;
                this.rowsByRowString[
                  copiedRow.store_date_size_color_group_str
                ] = cloneDeep(copiedRow);
                return [size_id, copiedRow];
              })
          ),
        }
      );
    },
    addColorGroupDelivery(colorGroup) {
      // Add new delivery to all stores for this colorgroup.
      // Prompt for delivery week
      const newWeek = window.prompt("Anna uusi viikkonumero. Huom, vain yksi!");
      if (
        !newWeek ||
        isNaN(newWeek) ||
        parseInt(newWeek) < 1 ||
        parseInt(newWeek) > 53
      ) {
        return;
      }

      for (const storeID in this.order.groups[colorGroup.color_group_str]
        .groups) {
        const justADateGroup = Object.values(
          this.order.groups[colorGroup.color_group_str].groups[storeID].groups
        )[0];
        this.addDelivery(justADateGroup, storeID, newWeek);
      }
    },
  },

  watch: {
    "choices.stores"() {
      this.$nextTick(() => {
        if (this.storeFilter) {
          this.$store.commit(
            "setInfoText",
            (this.storesById[this.storeFilter] || {}).name
          );
        }
      });
    },
    storeFilter() {
      this.hideTable = false;
      this.viewMode.expandedColorGroups = null;
      this.viewMode.editableDates = false;
      this.page = 1;
      if (this.storeFilter) {
        this.$store.commit(
          "setInfoText",
          (this.storesById[this.storeFilter] || {}).name
        );
      } else {
        this.$store.commit("setInfoText", "");
      }
      this.debouncedRedraw();
    },
    selectedFilter: {
      deep: true,
      handler(newValue) {
        this.loadingWrapper(true);
        this.slowDebouncedRedraw();
      },
    },
    viewMode: {
      deep: true,
      handler() {
        if (this.watcherDisabled.viewMode) {
          return;
        }
        console.log("viewMode watch handler");
        this.hideTable = false;
        this.debouncedRedraw();
      },
    },
    orderBy() {
      this.hideTable = false;
      this.debouncedRedraw();
    },
  },
};
</script>

<style scoped lang="scss">
td.size-td-first {
  border-left: 2px solid black;
}
td.size-td-last {
  border-right: 2px solid black;
}
table {
  border-spacing: 0px;
  border-collapse: collapse;
  width: 100%;
}
th {
  word-break: break-all;
}
td {
  padding: 0 0.5rem;
  border: 1px solid black;
  word-break: break-all;
  &.no-vertical-borders {
    border-top: 0;
    border-bottom: 0;
  }
  &.input-number-cell {
    padding: 0 4px !important;
    > * {
      height: 100%;
    }
  }
}
tr.dategroup-hidden {
  display: none;
}
.sticky-header {
  background: white;
  position: fixed;
  top: 0;
  z-index: 10;
}
.v-toolbar {
  overflow: hidden;
}
.hidden {
  display: none;
}
.linkedgroup-highlight {
  background-color: #dafada;
}

td.empty-td {
  background-color: #f5f5f5;
  border: 0;
  padding: 0;
  height: 0.5rem;
  width: 0.5rem;
  &.no-vertical-borders {
    border-top: 0;
    border-bottom: 0;
  }
}

tbody {
  tr:last-child {
    td {
      border-bottom: 1.5px solid black;
    }
  }
  &::before {
    content: "";
    display: table-row;
    height: 0.5rem;
  }
}
</style>
