<template>
  <span>
    <v-btn icon @click="openModal()" title="Muokkaa kommentteja"
      ><v-icon :class="{ notice: !!newSelectedProduct.comments }"
        >mdi-comment-text-outline</v-icon
      ></v-btn
    >
    <v-icon v-if="campaignSlug" color="green" title="Kampanjatuote">
      mdi-cart-outline</v-icon
    >
    <v-icon
      v-if="issuesArray.length > 0"
      color="red"
      title="Ongelmia tuotedatassa"
    >
      mdi-alert-circle</v-icon
    >

    <v-dialog v-model="dialog" width="1200">
      <v-card>
        <v-card-title class="headline grey lighten-2">Kommentti</v-card-title>

        <v-card-text>
          <h4>Ongelmia tuotetiedoissa</h4>
          <v-row>
            <v-col cols="2">
              <v-checkbox v-model="issueVARI" label="Väri"></v-checkbox>
            </v-col>
            <v-col cols="2">
              <v-checkbox v-model="issueKOKO" label="Koko"></v-checkbox>
            </v-col>
            <v-col cols="2">
              <v-checkbox v-model="issueTRYH" label="Tuoteryhmä"></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-textarea
            ref="editcomment"
            label="Kommentti"
            v-model="newSelectedProduct.comments"
            :rows="3"
          ></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text>
          <h4>Kampanjatiedot</h4>
          <v-select
            v-model="campaignSlug"
            label="Valitse kampanja"
            @change="campaignChanged"
            :items="campaigns"
          ></v-select>
          <v-text-field
            v-model="campaignSlug"
            :disabled="true"
            label="Kampanjan koodi"
          ></v-text-field>
          <v-text-field
            v-model="campaignName"
            :disabled="true"
            label="Kampanjan nimi"
          ></v-text-field>
          <v-textarea
            v-model="campaignText"
            :disabled="true"
            label="Kampanjan selite"
            :rows="3"
          ></v-textarea>
          <a target="_blank" :href="`/admin/products/campaign/`"
            >Kampanjojen hallinta</a
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
// Wrapper component to edit row or selectedproduct comments.

// EditComment will handle api call to persist data

import { modifyObj } from "../utils/utils";
import SelectedProductsEdit from "./SelectedProductsEdit.vue";

export default {
  name: "EditComments",

  mixins: [SelectedProductsEdit],

  data: () => ({
    dialog: false,
  }),

  methods: {
    openModal() {
      this.dialog = true;
      setTimeout(() => {
        this.$refs.editcomment.focus();
      }, 100);
    },
    saveComment() {
      this.saveSelectedProducts();
      this.dialog = false;
    },
    campaignChanged() {
      for (let campaign of (this.choices || {}).campaigns || []) {
        if (campaign.slug === this.campaignSlug) {
          this.campaignName = campaign.name;
          this.campaignText = campaign.description;
          return;
        }
      }
      this.campaignName = null;
      this.campaignText = null;
    },
  },

  computed: {
    campaigns() {
      const ret = [
        {
          text: "Ei kampanjaa",
          value: null,
        },
      ];
      for (let campaign of (this.choices || {}).campaigns || []) {
        ret.push({
          text: `${campaign.slug} - ${campaign.name}`,
          value: campaign.slug,
        });
      }
      return ret;
    },
    issuesArray() {
      return ((this.newSelectedProduct || {}).issues || "")
        .split(",")
        .filter(Boolean);
    },
    //"VARI", "KOKO", "TRYH"
    issueVARI: {
      get() {
        return this.issuesArray.includes("VARI");
      },
      set(newValue) {
        if (newValue) {
          if (!this.issuesArray.includes("VARI")) {
            this.issuesArray.push("VARI");
          }
        } else {
          this.issuesArray.splice(this.issuesArray.indexOf("VARI"), 1);
        }
        this.newSelectedProduct.issues = this.issuesArray.join(",");
      },
    },
    issueKOKO: {
      get() {
        return this.issuesArray.includes("KOKO");
      },
      set(newValue) {
        if (newValue) {
          if (!this.issuesArray.includes("KOKO")) {
            this.issuesArray.push("KOKO");
          }
        } else {
          this.issuesArray.splice(this.issuesArray.indexOf("KOKO"), 1);
        }
        console.log("Update issues", this.issuesArray);
        this.newSelectedProduct.issues = this.issuesArray.join(",");
        console.log("Update issues -> ", this.newSelectedProduct.issues);
      },
    },

    issueTRYH: {
      get() {
        return this.issuesArray.includes("TRYH");
      },
      set(newValue) {
        if (newValue) {
          if (!this.issuesArray.includes("TRYH")) {
            this.issuesArray.push("TRYH");
          }
        } else {
          this.issuesArray.splice(this.issuesArray.indexOf("TRYH"), 1);
        }
        this.newSelectedProduct.issues = this.issuesArray.join(",");
      },
    },

    campaignJSON() {
      if (!this.newSelectedProduct.campaign_info) {
        return {};
      }
      try {
        return JSON.parse(this.newSelectedProduct.campaign_info);
      } catch (err) {
        return {};
      }
    },
    campaignSlug: {
      get() {
        if (!this.campaignJSON) {
          return "";
        }
        return this.campaignJSON.slug || "";
      },
      set(newValue) {
        const json = this.campaignJSON;
        json.slug = newValue;
        this.newSelectedProduct.campaign_info = JSON.stringify(json);
      },
    },
    campaignName: {
      get() {
        if (!this.campaignJSON) {
          return "";
        }
        return this.campaignJSON.name || "";
      },
      set(newValue) {
        const json = this.campaignJSON;
        json.name = newValue;
        this.newSelectedProduct.campaign_info = JSON.stringify(json);
      },
    },
    campaignText: {
      get() {
        if (!this.campaignJSON) {
          return "";
        }
        return this.campaignJSON.text || "";
      },
      set(newValue) {
        const json = this.campaignJSON;
        json.text = newValue;
        this.newSelectedProduct.campaign_info = JSON.stringify(json);
      },
    },
  },
};
</script>

<style scoped>
.notice {
  color: #ff9500 !important;
  border: 1rem solid #fffa47;
  border-radius: 0.5rem;
}
</style>
