var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.order)?_c('v-container',{staticClass:"edit-order"},[_c('v-row',[_c('v-col',[_c('h2',[_vm._v("Tilaus #"+_vm._s(_vm.order.id)+": "+_vm._s(_vm.order.name))])]),_c('v-col',[_c('v-btn',{attrs:{"disabled":!_vm.changed},on:{"click":_vm.saveOrder}},[_vm._v("Tallenna")]),(_vm.saving)?_c('v-progress-linear',{attrs:{"indeterminate":true}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Nimi"},model:{value:(_vm.localValues.name),callback:function ($$v) {_vm.$set(_vm.localValues, "name", $$v)},expression:"localValues.name"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"label":"Status","items":_vm.availableStatuses},model:{value:(_vm.localValues.status),callback:function ($$v) {_vm.$set(_vm.localValues, "status", $$v)},expression:"localValues.status"}})],1),_c('v-col',[_c('v-select',{attrs:{"label":"Toimittaja","items":(_vm.choices.suppliers || []).map((row) => ({
            value: row.id,
            text: row.name,
          }))},model:{value:(_vm.localValues.supplier),callback:function ($$v) {_vm.$set(_vm.localValues, "supplier", $$v)},expression:"localValues.supplier"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":"Kommentti","rows":3},model:{value:(_vm.localValues.comment),callback:function ($$v) {_vm.$set(_vm.localValues, "comment", $$v)},expression:"localValues.comment"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"type":"text","label":"Sesonki"},model:{value:(_vm.localValues.season_str),callback:function ($$v) {_vm.$set(_vm.localValues, "season_str", $$v)},expression:"localValues.season_str"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":"Oletus-toimitusvko"},model:{value:(_vm.localValues.default_date),callback:function ($$v) {_vm.$set(_vm.localValues, "default_date", $$v)},expression:"localValues.default_date"}})],1),_c('v-col',[(_vm.choices.price_catalogs)?_c('v-select',{attrs:{"loading":_vm.pricesLoading,"disabled":_vm.pricesLoading,"label":"Hintakatalogi","items":Object.keys(_vm.choices.price_catalogs[_vm.localValues.supplier] || {}).map(
            (catalog) => {
              if (catalog === 'default') {
                return {
                  value: null,
                  text: 'Oletushinta',
                };
              }
              return {
                value: catalog,
                text: catalog,
              };
            }
          )},model:{value:(_vm.localValues.selected_price_catalog),callback:function ($$v) {_vm.$set(_vm.localValues, "selected_price_catalog", $$v)},expression:"localValues.selected_price_catalog"}}):_vm._e()],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":"Aleprosentti NETTO-hinnasta"},model:{value:(_vm.localValues.discount_percentage),callback:function ($$v) {_vm.$set(_vm.localValues, "discount_percentage", $$v)},expression:"localValues.discount_percentage"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":3}},[_c('v-select',{attrs:{"label":"Maksuehto","items":_vm.choices.payment_conditions},model:{value:(_vm.localValues.payment_condition),callback:function ($$v) {_vm.$set(_vm.localValues, "payment_condition", $$v)},expression:"localValues.payment_condition"}}),_c('a',{attrs:{"target":"_blank","href":`/admin/orders/paymentcondition/`}},[_vm._v("Maksuehtojen hallinta")])],1),_c('v-col',[_c('v-checkbox',{attrs:{"label":"EXT-tilaus"},model:{value:(_vm.localValues.ext_order),callback:function ($$v) {_vm.$set(_vm.localValues, "ext_order", $$v)},expression:"localValues.ext_order"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":3}},[_c('v-select',{attrs:{"label":"Tilauksen jako (vain TEKSOON viennissä)","items":_vm.choices.tekso_split_modes},model:{value:(_vm.localValues.tekso_split_mode),callback:function ($$v) {_vm.$set(_vm.localValues, "tekso_split_mode", $$v)},expression:"localValues.tekso_split_mode"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":2}},[_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.deleteOrder}},[_vm._v("Poista tilaus")])],1),_c('v-col',{attrs:{"cols":2}},[_c('v-btn',{attrs:{"disabled":!_vm.changed},on:{"click":_vm.saveOrder}},[_vm._v("Tallenna")])],1)],1)],1):_c('v-container',[_c('v-row',[_c('h2',[_vm._v("Muokkaa tilausta...")])]),_c('v-row',[_c('v-col',[_c('v-progress-linear',{attrs:{"indeterminate":true}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }