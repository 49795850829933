<template>
  <div
    v-if="trueVisible"
    ref="main"
    :style="myStyle"
    v-observe-visibility="visibilityChanged"
  >
    <slot></slot>
  </div>
  <div v-else ref="main" v-observe-visibility="visibilityChanged">...</div>
</template>

<script>
export default {
  name: "DisappearingComponent",

  props: ["visible"],

  data: () => ({
    height: "10px",
    trueVisible: false,
  }),

  mounted() {
    this.height = `${this.$refs.main.offsetHeight}px`;
  },

  methods: {
    visibilityChanged(isVisible) {
      this.trueVisible = isVisible;
    },
  },

  computed: {
    myStyle() {
      if (this.visible) {
        return null;
      }
      return { "min-height": this.height };
    },
  },

  watch: {
    visible(newValue) {
      this.height = `${this.$refs.main.offsetHeight}px`;
      this.trueVisible = newValue;
    },
  },
};
</script>

<style scoped></style>
