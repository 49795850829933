<template>
  <span
    ref="me"
    :style="{
      'writing-mode': transformed ? 'vertical-lr' : 'horizontal-tb',
      transform: transformed
        ? partially
          ? 'rotate(225deg)'
          : 'rotate(190deg)'
        : 'none',
      'padding-top': transformed ? '0.5rem' : '0',
      'padding-bottom': transformed ? '0.5rem' : '0',
      'font-size': smallerFont ? '0.8rem' : fontSize,
    }"
  >
    {{ text }}
  </span>
</template>

<script>
export default {
  name: "ThinSpan",

  props: {
    text: {
      type: String,
      default: "",
    },
    "font-size": {
      type: String,
      default: "1rem",
    },
  },

  data: () => ({
    transformed: false,
    partially: false,
    smallerFont: false,
  }),

  mounted() {
    this.$nextTick(() => {
      this.refresh();
    });
    setInterval(() => {
      this.refresh();
    }, Math.random() * 1000 + 1000);
  },

  methods: {
    refresh() {
      if (!this.$refs.me || !this.$refs.me.parentElement) {
        return;
      }
      const myWidth = this.$refs.me.parentElement.offsetWidth;
      this.partially = false;
      this.smallerFont = false;
      if (myWidth < this.textWidth) {
        this.smallerFont = true;
      }
      if (myWidth < this.textWidth * 0.8) {
        if (myWidth > this.textWidth / 2) {
          this.partially = true;
        } else {
          this.partially = false;
        }
        this.transformed = true;
      } else {
        this.transformed = false;
      }
    },
  },

  computed: {
    textWidth() {
      return 14 * this.text.length;
    },
  },

  watch: {
    text() {
      this.$nextTick(() => {
        this.refresh();
      });
    },
  },
};
</script>

<style scoped></style>
