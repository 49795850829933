import Vue from "vue";
import Vuex from "vuex";
import { cloneDeep } from "lodash";

export const ordergrid = {
  namespaced: true,
  state: () => ({
    paintedOrderRows: {}, // painted order rows keyed by store_date_size_color_group_str
    shiftSelectStartCell: null, // the cell where the shift select started. This is an actual DOM element
    shiftSelectEndCell: null, // the cell where the shift select ended. This is an actual DOM element
    shiftSelectAction: null,
    shiftSelectIntVal: null,
    clipBoard: null,
    shiftSelectSum: null,
  }),
  mutations: {
    setPaintedOrderRows(state, newPaintedOrderrows) {
      Vue.set(state, "paintedOrderRows", newPaintedOrderrows);
    },
    setShiftSelectStartCell(state, newShiftSelectStartCell) {
      Vue.set(state, "shiftSelectStartCell", newShiftSelectStartCell);
      state.shiftSelectIntVal = null;
    },
    setShiftSelectEndCell(state, newShiftSelectEndCell) {
      Vue.set(state, "shiftSelectEndCell", newShiftSelectEndCell);
      state.shiftSelectIntVal = null;
    },
    setShiftSelectAction(state, newShiftSelectAction) {
      if (newShiftSelectAction && newShiftSelectAction !== "input") {
        state.shiftSelectIntVal = null;
      }
      Vue.set(state, "shiftSelectAction", newShiftSelectAction);
    },
    setShiftSelectIntVal(state, intVal) {
      Vue.set(state, "shiftSelectIntVal", intVal);
    },
    setClipBoard(state, newClipBoard) {
      Vue.set(state, "clipBoard", newClipBoard);
    },
    setShiftSelectSum(state, newShiftSelectSum) {
      Vue.set(state, "shiftSelectSum", newShiftSelectSum);
    },
  },
  actions: {},
  getters: {},
};
