import Vue from "vue";
import Vuex from "vuex";
import { cloneDeep } from "lodash";

export const priceMismatchModal = {
  namespaced: true,
  state: () => ({
    showModal: false,
    newSelectedProducts: null,
    updateFunc: null,
  }),
  mutations: {
    openModal(state, { newSelectedProducts, updateFunc }) {
      Vue.set(state, "newSelectedProducts", newSelectedProducts);
      Vue.set(state, "updateFunc", updateFunc);
      state.showModal = true;
    },
    closeModal(state) {
      state.showModal = false;
    },
  },
  actions: {},
  getters: {},
};
