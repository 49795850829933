<template>
  <div>
    <slot
      :setDeliveryDateFunc="setDeliveryDate"
      :selectedDate="selectedDate"
    ></slot>
  </div>
</template>

<script>
// Wrapper component to edit row values.
// Use like this:
// <template v-slot="{ setDeliveryDateFunc }">
//   <v-btn @click="setDeliveryDateFunc()">Click</v-btn>
// </template>
//
// EditColorGroupDate will handle api call to persist data

import { modifyObj } from "../utils/utils";

export default {
  name: "EditColorGroupDate",

  props: {
    colorGroup: {
      type: Object,
    },
    dateGroupIndex: {
      type: Number,
    },
    dateGroupStore: {
      type: Number,
    },
    order: {
      type: Object,
    },
  },

  data: () => ({
    newColorGroup: {},
  }),

  mounted() {
    this.newColorGroup = this._.cloneDeep(this.colorGroup);
  },

  computed: {
    oldColorGroup() {
      return this.colorGroup;
    },
    pickedOrderId() {
      return this.$route.params.orderId;
    },
    selectedProducts() {
      return this.order.selectedproducts;
    },
    selectedDate() {
      if (!this.newColorGroup || !this.newColorGroup.groups) {
        return null;
      }
      for (const storeGroup of Object.values(this.newColorGroup.groups)) {
        if (storeGroup.id !== this.dateGroupStore) {
          continue;
        }
        let i = 0;
        for (const dateGroup of Object.values(storeGroup.groups)) {
          if (this.dateGroupIndex === i) {
            for (const row of Object.values(dateGroup.groups)) {
              return row.delivery_date;
            }
          }
          i++;
        }
      }
      return null;
    },
  },

  methods: {
    setDeliveryDate(newDeliveryDate) {
      for (const storeGroup of Object.values(this.newColorGroup.groups)) {
        if (storeGroup.id !== this.dateGroupStore) {
          continue;
        }
        let i = 0;
        for (const dateGroup of Object.values(storeGroup.groups)) {
          if (this.dateGroupIndex === i) {
            for (const row of Object.values(dateGroup.groups)) {
              if (row.delivery_date != newDeliveryDate) {
                this.oldDeliveryDate = row.delivery_date;
              }
              row.delivery_date = newDeliveryDate;
            }
          }
          i++;
        }
      }
      this.newDeliveryDate = newDeliveryDate;
      if (!this.oldDeliveryDate) {
        this.oldDeliveryDate = this.newDeliveryDate;
      }
      this.persist();
    },
    persist() {
      const newColorGroup = this._.cloneDeep(this.newColorGroup);
      const oldColorGroup = this._.cloneDeep(this.oldColorGroup);
      const newColorGroupRef = this.newColorGroup;
      const oldColorGroupRef = this.oldColorGroup;

      this.$store.commit("addJob", {
        callback: () => {
          console.debug("persist EditColorGroupDate: ", newColorGroup);
          // Modify here for UX, then after response for the ids
          modifyObj(newColorGroupRef, newColorGroup);
          modifyObj(oldColorGroupRef, newColorGroup);
          const rows = [];
          for (const storeGroup of Object.values(newColorGroup.groups)) {
            for (const dateGroup of Object.values(storeGroup.groups)) {
              for (const row of Object.values(dateGroup.groups)) {
                rows.push(row);
              }
            }
          }
          return this.axios
            .post(`/api/orders/order/${this.pickedOrderId}/update_rows/`, {
              rows: rows,
            })
            .then((resp) => {
              console.log("saveQuantity resp:", resp);
              this.axios
                .get(
                  `/api/orders/order/${this.pickedOrderId}/?grouping=color_group,store,date`
                )
                .then((resp) => {
                  for (const storeId in resp.data.groups[
                    newColorGroup.color_group_str
                  ].groups) {
                    this.$set(
                      oldColorGroupRef.groups,
                      storeId,
                      resp.data.groups[newColorGroup.color_group_str].groups[
                        storeId
                      ]
                    );
                    this.$emit("updated");
                  }
                });
            });
        },
        undo: () => {
          console.debug("Undoing EditColorGroupDate: ", oldColorGroup);
          // Modify here for UX, then after response for the ids
          modifyObj(newColorGroupRef, oldColorGroup);
          modifyObj(oldColorGroupRef, oldColorGroup);
          const rows = [];
          for (const storeGroup of Object.values(oldColorGroup.groups)) {
            for (const dateGroup of Object.values(storeGroup.groups)) {
              for (const row of Object.values(dateGroup.groups)) {
                rows.push(row);
              }
            }
          }
          return this.axios
            .post(`/api/orders/order/${this.pickedOrderId}/update_rows/`, {
              rows: rows,
            })
            .then((resp) => {
              this.axios
                .get(
                  `/api/orders/order/${this.pickedOrderId}/?grouping=color_group,store,date`
                )
                .then((resp) => {
                  for (const storeId in resp.data.groups[
                    newColorGroup.color_group_str
                  ].groups) {
                    this.$set(
                      oldColorGroupRef.groups,
                      storeId,
                      resp.data.groups[newColorGroup.color_group_str].groups[
                        storeId
                      ]
                    );
                    this.$emit("updated");
                  }
                });
            });
        },
      });
    },
  },

  watch: {
    oldColorGroup: {
      handler(newValue) {
        this.newColorGroup = this._.cloneDeep(newValue);
      },
      deep: true,
    },
  },
};
</script>
