export const hyperlinks = {
  namespaced: true,
  state: () => ({
    linkedColorGroup: null,
    linkTimeoutVar: null,
  }),
  mutations: {
    setLinkedColorGroup(state, color_group_str) {
      state.linkedColorGroup = color_group_str;
    },
    setLinkTimeoutVar(state, timeout_var) {
      state.linkTimeoutVar = timeout_var;
    },
  },
  actions: {},
  getters: {},
};
