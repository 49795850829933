import Vue from "vue";


const changesToMake = {};
const changesToMakeOrdering = [];
let interval = null;

const clearChangeKey = (key) => {
  delete changesToMake[key];
  const index = changesToMakeOrdering.indexOf(key);
  if (index !== -1) {
    changesToMakeOrdering.splice(index, 1);
  }
};

function makeChanges(state) {
  const visibleComponentCount = Object.values(state.visibleComponents).filter(Boolean).length;
  let changesMade = 0;
  const orderings = changesToMakeOrdering.slice().reverse();
  for (const componentId of orderings) {
    let newValue = changesToMake[componentId];
    if (newValue === undefined) {
      continue;
    }
    if (state.visibleComponents[componentId] === newValue) {
      clearChangeKey(componentId);
      continue;
    }

    // Don't hide if visibleComponentCount is less than 100
    if (newValue === false && visibleComponentCount < state.maxVisibleComponents) {
      continue;
    }

    Vue.set(state.visibleComponents, componentId, newValue);
    clearChangeKey(componentId);
    if (newValue) {
      console.log("Showing", componentId);
      changesMade++;
    }

    if (changesMade > 10) {
      console.log("Breaking after 10 changes.");
      interval = setTimeout(() => makeChanges(state), 200);
      return;
    }
  }
  console.log("Made", changesMade, "changes.");
  interval = null;
}


export const disappearingHelper = {
  namespaced: true,
  state: () => ({
    visibleComponents: {},
    maxVisibleComponents: 20,
  }),
  mutations: {
    setComponentVisible(state, { componentId, visible }) {
      // Debounce changes
      clearInterval(interval);
      interval = setTimeout(() => makeChanges(state), 200);
      changesToMake[componentId] = visible;
      changesToMakeOrdering.push(componentId);
    },
    setMaxVisibleComponents(state, maxVisibleComponents) {
      state.maxVisibleComponents = maxVisibleComponents;
    },
    clearComponentVisible(state) {
      Vue.set(state, 'visibleComponents', {});
      for (const key in changesToMake) {
        clearChangeKey(key);
      }
    }
  },
  actions: {},
  getters: {
    componentVisible: (state) => (componentId) => {
      return state.visibleComponents[componentId] || false;
    }
  },
};
